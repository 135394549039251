import {listOperations} from '../operations';
import {all, fork, takeLatest} from '@redux-saga/core/effects';

// pasar lista simil con un objeto de argumentos, eso despues lo rescata el sagas respectivo, detalle payload
export class Builder {
  constructor(name, listOperationWished) {
    this.name = name;
    this.actions = {};
    let initialState = {};
    const cases = [];
    let sagasFork = [];
    listOperations.forEach((operation) => {
      listOperationWished.forEach((nameOperation) => {
        if (nameOperation === operation.name) {
          Object.assign(this.actions, operation.action.function(name));
          Object.assign(initialState, operation.reducer.initialState);
          operation.reducer.cases.forEach((caseAction) => {
            cases.push(caseAction);
          });
          sagasFork.push(
            fork(function* () {
              yield takeLatest(
                operation.sagas.sagasActionName + name,
                operation.sagas.function(name),
              );
            }),
          );
        }
      });
    });
    this.reducers = {
      reducer: () => {
        return (state = initialState, action) => {
          let newState = state;
          cases.forEach((caseAction) => {
            if (action.type === caseAction.name + this.name) {
              let dataLoad = {};
              if (action.payload) {
                if (action.payload.dataLoad) {
                  dataLoad = action.payload.dataLoad;
                }
              }
              newState = {...state, ...caseAction.newState, ...dataLoad};
            }
          });
          return newState;
        };
      },
    };
    this.sagas = {
      sagas: () => {
        return function* rootSagas() {
          yield all(sagasFork);
        };
      },
    };
  }
  getReducers() {
    return this.reducers;
  }
  getActions() {
    return this.actions;
  }
  getSagas() {
    return this.sagas;
  }
}
