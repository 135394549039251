import {put, call} from '@redux-saga/core/effects';
import {deleteDatabase} from '../services/firebase/database';

export const operationDeleteFirebaseDatabase = {
  name: 'DELETE_FIREBASE_DATABASE_',
  reducer: {
    initialState: {
      deletingFirebaseDatabase: false,
      deletedFirebaseDatabase: false,
      deleteFailedFirebaseDatabase: false,
    },
    cases: [
      {
        name: 'DELETE_FIREBASE_DATABASE_',
        newState: {
          deletingFirebaseDatabase: true,
          deletedFirebaseDatabase: false,
          deleteFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'DELETED_FIREBASE_DATABASE_',
        newState: {
          deletingFirebaseDatabase: false,
          deletedFirebaseDatabase: true,
          deleteFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'DELETE_FAILED_FIREBASE_DATABASE_',
        newState: {
          deletingFirebaseDatabase: false,
          deletedFirebaseDatabase: false,
          deleteFailedFirebaseDatabase: true,
        },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        deleteFirebaseDatabase: (payload) => {
          return {type: 'DELETE_FIREBASE_DATABASE_' + name, payload: payload};
        },
        deletedFirebaseDatabase: () => {
          return {type: 'DELETED_FIREBASE_DATABASE_' + name};
        },
        deleteFailedFirebaseDatabase: () => {
          return {type: 'DELETE_FAILED_FIREBASE_DATABASE_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'DELETE_FIREBASE_DATABASE_',
    function: (name) => {
      return function* delet({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path} = payload;
          yield call(deleteDatabase, path);
          yield put({type: 'DELETE_SUCCESS'});
        } catch (e) {
          yield put({type: 'DELETE_FAILED'});
        }
      };
    },
  },
};
