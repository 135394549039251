import React from 'react';

export const laborRegistrationWithOtPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/labor-registration-with-ot',
        component: React.lazy(() => import('./Page')),
      },
    ],
  },
];
