import {
  LOAD_ALERT,
  LOADED_ALERT,
  CREATED_SUCCESS,
  CREATED_FAILED,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  SEND_SUCCESS,
  SEND_FAILED,
  DELETE_SUCCESS,
  DELETE_FAILED,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  load: false,
  createFailed: false,
  createSuccess: false,
  updateSuccess: false,
  updateFailed: false,
  sendSuccess: false,
  sendFailed: false,
  deleteSuccess: false,
  deleteFailed: false,
};

const alertReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_ALERT: {
      return {
        ...state,
        load: true,
        createFailed: false,
        createSuccess: false,
        updateSuccess: false,
        updateFailed: false,
        sendSuccess: false,
        sendFailed: false,
        deleteSuccess: false,
        deleteFailed: false,
      };
    }
    case LOADED_ALERT: {
      return {
        ...state,
        load: false,
      };
    }
    case CREATED_SUCCESS: {
      return {
        ...state,
        load: false,
        createSuccess: true,
      };
    }
    case CREATED_FAILED: {
      return {
        ...state,
        load: false,
        createFailed: true,
      };
    }
    case UPDATE_SUCCESS: {
      return {
        ...state,
        load: false,
        updateSuccess: true,
      };
    }
    case UPDATE_FAILED: {
      return {
        ...state,
        load: false,
        updateFailed: true,
      };
    }
    case SEND_SUCCESS: {
      return {
        ...state,
        load: false,
        sendSuccess: true,
      };
    }
    case SEND_FAILED: {
      return {
        ...state,
        load: false,
        sendFailed: true,
        ...(action.payload ? action.payload.dataLoad : null),
      };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        load: false,
        deleteSuccess: true,
      };
    }
    case DELETE_FAILED: {
      return {
        ...state,
        load: false,
        deleteFailed: true,
      };
    }
    default:
      return state;
  }
};
export default alertReducer;
