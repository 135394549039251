import {eventChannel} from 'redux-saga';
import {database} from './index';
import {generateUidJustNumber} from '../../../shared/functions/uidd';

export const loadDatabase = (path) =>
  eventChannel((emitter) => {
    const databaseReference = database
      .ref(path)
      .orderByChild('active')
      .equalTo(true);
    databaseReference.on(
      'value',
      function (snapshot) {
        emitter({
          data: snapshot.val(),
        });
      },
      function (errorObject) {
        console.log('The read failed: ' + errorObject.code);
      },
    );
    return () => databaseReference.off('value');
  });

export const loadDatabasePromise = async (path, id) => {
  return await database.ref(path).child(id).get();
};

export const setDatabase = async (documentPath, document) => {
  const newId = generateUidJustNumber();
  const databaseReference = database.ref(documentPath + newId);
  return await databaseReference.set(document);
};

export const deleteDatabase = async (documentPath) => {
  const databaseReference = database.ref(documentPath);
  return await databaseReference.delete();
};

export const updateDatabase = async (documentPath, dataDocument) => {
  const databaseReference = database.ref(documentPath);
  return await databaseReference.update(dataDocument);
};
