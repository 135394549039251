import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  SIGN_IN_USER_SUCCESS,
  SIGN_OUT_USER_SUCCESS,
  SIGN_IN_USER,
  SIGN_IN_USER_FAILED,
  SIGN_OUT_USER,
  RESETTING_PASSWORD,
  RESETTING_PASSWORD_SUCCESS,
  RESETTING_PASSWORD_FAILED,
  AUTH_CLEAN_STORE,
  AUTH_SET_BRANCH_USER,
  COMPANY_INFO_LOAD,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  singInLoading: false,
  singInLoaded: false,
  singInError: false,
  errorLoading: false,
  resetLoading: false,
  resetLoaded: false,
  resetError: false,
  singOutLoading: false,
  singOutLoaded: false,
  errorMessage: '',
  user: null,
  token: null,
  companyInfo: [{nameBranch: 'Sucursal'}],
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case AUTH_SET_BRANCH_USER: {
      return {
        ...state,
        user: {...state.user, branchSelected: action.payload},
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case COMPANY_INFO_LOAD: {
      return {
        ...state,
        companyInfo: action.payload,
      };
    }
    // sign in
    case SIGN_IN_USER: {
      return {
        ...state,
        singInLoading: true,
        singInLoaded: false,
        singInError: false,
      };
    }
    case SIGN_IN_USER_SUCCESS: {
      return {
        ...state,
        singInLoading: false,
        singInLoaded: true,
        singInError: false,
        user: action.payload,
      };
    }
    case SIGN_IN_USER_FAILED: {
      return {
        ...state,
        singInLoading: false,
        singInLoaded: false,
        singInError: true,
        errorMessage: action.payload,
      };
    }
    // sign out
    case SIGN_OUT_USER: {
      return {...state, singOutLoading: true, singOutLoaded: false};
    }
    case SIGN_OUT_USER_SUCCESS: {
      return {INIT_STATE, singOutLoaded: true};
    }
    // resette password
    case RESETTING_PASSWORD: {
      return {
        ...state,
        resetLoading: true,
        resetLoaded: false,
        resetError: false,
      };
    }
    case RESETTING_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetLoading: false,
        resetLoaded: true,
        resetError: false,
      };
    }
    case RESETTING_PASSWORD_FAILED: {
      return {
        ...state,
        resetLoading: false,
        resetLoaded: false,
        resetError: true,
      };
    }
    //
    case AUTH_CLEAN_STORE: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
export default authReducer;
