export const operationUpdateLocalState = {
  name: 'UPDATE_LOCAL_STATE',
  reducer: {
    initialState: {
      updatingLocalState: false,
      updatedLocalState: false,
      updateFailedLocalState: false,
    },
    cases: [
      {
        name: 'UPDATE_LOCAL_STATE',
        newState: {
          updatingLocalState: true,
          updatedLocalState: false,
          updateFailedLocalState: false,
        },
      },
      {
        name: 'UPDATED_LOCAL_STATE',
        newState: {
          updatingLocalState: false,
          updatedLocalState: true,
          updateFailedLocalState: false,
        },
      },
      {
        name: 'UPDATE_FAILED_LOCAL_STATE',
        newState: {
          updatingLocalState: false,
          updatedLocalState: false,
          updateFailedLocalState: true,
        },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        updateLocalState: (payload) => {
          return {type: 'UPDATE_LOCAL_STATE' + name, payload: payload};
        },
        updatedLocalState: () => {
          return {type: 'UPDATED_LOCAL_STATE' + name};
        },
        updateFailedLocalState: () => {
          return {type: 'UPDATE_FAILED_LOCAL_STATE' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'UPDATE_LOCAL_STATE',
    function: (name) => {
      return function* update({payload}) {};
    },
  },
};
