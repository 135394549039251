import {put, call} from '@redux-saga/core/effects';
import {updateDatabase} from '../services/firebase/database';

export const operationUpdateFirebaseDatabase = {
  name: 'UPDATE_FIREBASE_DATABASE',
  reducer: {
    initialState: {
      updatingFirebaseDatabase: false,
      updatedFirebaseDatabase: false,
      updateFailedFirebaseDatabase: false,
    },
    cases: [
      {
        name: 'UPDATE_FIREBASE_DATABASE_',
        newState: {
          updatingFirebaseDatabase: true,
          updatedFirebaseDatabase: false,
          updateFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'UPDATED_FIREBASE_DATABASE_',
        newState: {
          updatingFirebaseDatabase: false,
          updatedFirebaseDatabase: true,
          updateFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'UPDATE_FAILED_FIREBASE_DATABASE_',
        newState: {
          updatingFirebaseDatabase: false,
          updatedFirebaseDatabase: false,
          updateFailedFirebaseDatabase: true,
        },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        updateFirebaseDatabase: (payload) => {
          return {type: 'UPDATE_FIREBASE_DATABASE_' + name, payload: payload};
        },
        updatedFirebaseDatabase: () => {
          return {type: 'UPDATED_FIREBASE_DATABASE_' + name};
        },
        updateFailedFirebaseDatabase: () => {
          return {type: 'UPDATE_FAILED_FIREBASE_DATABASE_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'UPDATE_FIREBASE_DATABASE_',
    function: (name) => {
      return function* update({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, dataDocument} = payload;
          yield call(updateDatabase, path, dataDocument);
          yield put({type: 'SEND_SUCCESS'});
        } catch (e) {
          yield put({type: 'SEND_FAILED'});
        }
      };
    },
  },
};
