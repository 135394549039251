import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {isNotNullOrUndefined} from '../../../functions/validations';

export class PaymentDialog extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {}

  openPayment(auth) {
    if (!auth) {
      return false;
    }
    if (!auth.companyInfo) {
      return false;
    }
    if (!auth.companyInfo[0]) {
      return false;
    }
    if (!isNotNullOrUndefined(auth.companyInfo[0].paidOut)) {
      return false;
    }
    if (!auth.companyInfo[0].paidOut) {
      return true;
    }
    return false;
  }
  openPaymentLink(auth) {
    if (!auth) {
      return null;
    }
    if (!auth.companyInfo) {
      return null;
    }
    if (!auth.companyInfo[0]) {
      return null;
    }
    if (auth.companyInfo[0].paymentLink) {
      return auth.companyInfo[0].paymentLink;
    }
    return null;
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.openPayment(this.props.auth)}
          onClose={() => {}}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          ><DialogTitle id='alert-dialog-title'>{'Pago'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {'No tienes acceso a la plataforma. Hay pagos pendientes'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              style={{width: 110}}
              onClick={() => {
                window.open(
                  'mailto:contacto@maintdata.cl?subject=Soporte&body=Contactanos',
                );
              }}
              color='primary'>
              CONTACTO
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
