import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {fleetFleetsPagesConfig} from './fleetVehicles';
import {materialsPagesConfig} from './materials';
import {humanResourcesPagesConfig} from './humanResources';
import {settingPagesConfig} from './setting';
import {operationsPagesConfig} from './operations';
import {sparePartsPagesConfig} from './spareParts';
import {workOrderPagesConfig} from './workOrder';
import {laborRegistrationPagesConfig} from './laborRegistration';
import {maintenancePlanPagesConfig} from './maintenancePlan';
import {graphicsPagesConfig} from './graphics';
import {HelpPagesConfig} from './help';
import {laborRegistrationInformPagesConfig} from './laborRegistrationInform';
import {adminPagesConfig} from './maintData';
import {laborRegistrationWithOtPagesConfig} from './laborRegistrationWithOt';
import {massiveUploadConfig} from './massiveUpload';
import {DashboardPagesConfig} from './dashboard';
import {initialUrlPage} from '../redux/sagas/authFunctions/functions';
import {EmptyPagesConfig} from './emptyPage';

const routeConfigs = [
  ...EmptyPagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...materialsPagesConfig,
  ...humanResourcesPagesConfig,
  ...settingPagesConfig,
  ...operationsPagesConfig,
  ...sparePartsPagesConfig,
  ...workOrderPagesConfig,
  ...laborRegistrationPagesConfig,
  ...laborRegistrationWithOtPagesConfig,
  ...laborRegistrationInformPagesConfig,
  ...maintenancePlanPagesConfig,
  ...graphicsPagesConfig,
  ...fleetFleetsPagesConfig,
  ...HelpPagesConfig,
  ...adminPagesConfig,
  ...massiveUploadConfig,
  ...DashboardPagesConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrlPage} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
