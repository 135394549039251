import {put, call, all, select} from '@redux-saga/core/effects';
import {
  setDocumentFirestoreWithId,
  uploadFileWithStorage,
} from '../services/firebase/firestore';
import {generateUidJustNumber} from '../../shared/functions/uidd';

const getAuth = (state) => state.auth;

export const operationCreateWithFiles = {
  name: 'CREATE_WITH_FILES',
  reducer: {
    initialState: {creating: false, created: false, createFailed: false},
    cases: [
      {
        name: 'CREATE_WITH_FILES_',
        newState: {creating: true, created: false, createFailed: false},
      },
      {
        name: 'CREATED_WITH_FILES_',
        newState: {creating: false, created: true, createFailed: false},
      },
      {
        name: 'CREATE_WITH_FILES_FAILED_',
        newState: {creating: false, created: false, createFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        createWithFiles: (payload) => {
          return {type: 'CREATE_WITH_FILES_' + name, payload: payload};
        },
        createdWithFiles: () => {
          return {type: 'CREATED_WITH_FILES_' + name};
        },
        createWithFilesFailed: () => {
          return {type: 'CREATE_WITH_FILES_FAILED_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'CREATE_WITH_FILES_',
    function: (name) => {
      return function* createWithFiles({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const idElements = generateUidJustNumber();
          const {path, dataCreate, files} = payload;
          const auth = yield select(getAuth);
          if (
            auth.user.branchSelected &&
            auth.user.branchSelected !== 'todos'
          ) {
            const filesDocument = [];
            yield all(
              files.map((file) => {
                const filePath = file.path + '/' + idElements + '/' + file.name;
                filesDocument.push({
                  filePath: filePath,
                  name: file.name,
                  type: file.type,
                  date: new Date(),
                });
                return call(uploadFileWithStorage, file.file, filePath);
              }),
            );
            let dataDocument = {...dataCreate, files: filesDocument};
            const dataWithBranch = {
              ...dataDocument,
              branch: auth.user.branchSelected.idBranch,
            };
            yield call(
              setDocumentFirestoreWithId,
              path,
              dataWithBranch,
              idElements,
            );
            yield put({type: 'CREATED_SUCCESS'});
          } else {
            yield put({type: 'BRANCH_NO_SELECTED'});
            yield put({type: 'CREATED_FAILED'});
          }
        } catch (e) {
          console.log('error al crear con archivos', e);
          yield put({type: 'CREATED_FAILED'});
        }
      };
    },
  },
};
