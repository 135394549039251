import {put, call} from '@redux-saga/core/effects';
import {setDocumentFirestore} from '../services/firebase/firestore';

export const operationCreateWithOutBranch = {
  name: 'CREATE_WITH_OUT_BRANCH',
  reducer: {
    initialState: {creating: false, created: false, createFailed: false},
    cases: [
      {
        name: 'CREATE_WITH_OUT_BRANCH_',
        newState: {creating: true, created: false, createFailed: false},
      },
      {
        name: 'CREATED_WITH_OUT_BRANCH_',
        newState: {creating: false, created: true, createFailed: false},
      },
      {
        name: 'CREATE_FAILED_WITH_OUT_BRANCH_',
        newState: {creating: false, created: false, createFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        create: (payload) => {
          return {type: 'CREATE_WITH_OUT_BRANCH_' + name, payload: payload};
        },
        created: () => {
          return {type: 'CREATED_WITH_OUT_BRANCH_' + name};
        },
        createFailed: () => {
          return {type: 'CREATE_FAILED_WITH_OUT_BRANCH_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'CREATE_WITH_OUT_BRANCH_',
    function: (name) => {
      return function* create({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, dataCreate} = payload;
          yield call(setDocumentFirestore, path, dataCreate);
          yield put({type: 'CREATED_SUCCESS'});
        } catch (e) {
          console.log('Error al crear sin sucursal', e);
          yield put({type: 'CREATED_FAILED'});
        }
      };
    },
  },
};
