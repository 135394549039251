import React, {useContext, useEffect, useState} from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import {ContentView, ThemeSetting} from '../../../index';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppContext from '../../../utility/AppContext';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import Alerts from '../../../../shared/components/Alerts';
import {LoadingDialog} from '../../../../shared/Loading';
import {connect} from 'react-redux';
import {simpleTimer} from '../../../../shared/functions/timer';
import {PaymentDialog} from '../../../../shared/components/Dialog/Payment';
import {Button} from '@material-ui/core';
import AppFooter from '../HorDefault/AppFooter';
import AppFixedFooter from '../HorDefault/AppFixedFooter';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    alert: state.alert,
  };
};

const HeaderUserMiniLayout = (props) => {
  const {footer, themeStyle, layoutType, footerType} = useContext(AppContext);
  const classes = useStyles({footer, themeStyle});
  const [titleAlert, setTitleAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const resetAlert = () => {
    setOpenAlert(false);
    setTypeAlert('success');
    setTitleAlert('');
  };

  useEffect(() => {
    if (props.alert.load) {
      setOpenLoading(true);
      setOpenAlert(false);
    }
    if (!props.alert.load) {
      setOpenLoading(false);
    }
    if (props.alert.createFailed) {
      setTypeAlert('error');
      setTitleAlert('Se ha producido un error.');
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
    if (props.alert.createSuccess) {
      setTypeAlert('success');
      setTitleAlert('Se ha creado con éxito.');
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
    if (props.alert.updateSuccess) {
      setTypeAlert('success');
      setTitleAlert('Se ha realizado con éxito.');
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
    if (props.alert.updateFailed) {
      setOpenLoading(false);
      setTypeAlert('error');
      setTitleAlert('Se ha producido un error.');
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
    if (props.alert.sendSuccess) {
      setTypeAlert('success');
      setTitleAlert('Se ha realizado con éxito.');
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
    if (props.alert.sendFailed) {
      setTypeAlert('error');
      setTitleAlert(
        props.alert.errorMessage
          ? props.alert.errorMessage
          : 'Se ha producido un error. ',
      );
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
    if (props.alert.deleteSuccess) {
      setTypeAlert('success');
      setTitleAlert('Se ha eliminado con éxito.');
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
    if (props.alert.deleteFailed) {
      setTypeAlert('error');
      setTitleAlert('Se ha producido un error.');
      setOpenAlert(true);
      simpleTimer(8000).then((_) => {
        resetAlert();
      });
    }
  }, [props.alert]);

  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppHeader />

      <Box className={classes.mainContent}>
        <AppSidebar />
        <Hidden mdDown>
          <Box className={classes.mainContainer}>
            <ContentView />
          </Box>
        </Hidden>

        <Hidden lgUp>
          <Box className={classes.mainContainerFull}>
            <ContentView />
          </Box>
        </Hidden>
        <Alerts
          title={titleAlert}
          open={openAlert}
          type={typeAlert}
          close={() => {
            setOpenAlert(false);
          }}
        />
        <LoadingDialog open={openLoading} type={'info'} close={() => {}} />
        <PaymentDialog auth={props.auth} />
      </Box>
      {/*<Box style={{position: 'fixed', left: 200, bottom: 20, width: '95%'}}>
        <Box alignItems='center' flexDirection='row' display='flex'>
          <Box>Copy right @crema 2020</Box>
        </Box>
      </Box>*/}
    </Box>
  );
};

export default connect(mapStateToProps)(HeaderUserMiniLayout);
