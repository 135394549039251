export const configTestApp = {
  apiKey: 'AIzaSyBwHpaGMJ2mimRGmskwA4e2l60_G2GjTmc',
  authDomain: 'main-data-test.firebaseapp.com',
  databaseURL: 'https://main-data-test-default-rtdb.firebaseio.com',
  projectId: 'main-data-test',
  storageBucket: 'main-data-test.appspot.com',
  messagingSenderId: '332064404961',
  appId: '1:332064404961:web:5c2d8c9bcc7a75da544ea0',
};

export const configProductionApp = {};

export const httpsFunctionsTestApp = {};

export const httpsFunctionsProductionApp = {};
