import React from 'react';

export const adminPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/companies',
        component: React.lazy(() => import('./admin/Page')),
      },
      {
        path: '/plans',
        component: React.lazy(() => import('./admin/Page')),
      },
      {
        path: '/currency',
        component: React.lazy(() => import('./admin/Page')),
      },
      {
        path: '/usersCompany',
        component: React.lazy(() => import('./admin/Page')),
      },
    ],
  },
];
