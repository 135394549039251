export const num = (number) => {};

export const minNum = (number, min) => {
  if (typeof number !== 'number') {
    return false;
  }
  return number >= min ? true : false;
};

export const maxNum = (number, max) => {
  return number <= max ? true : false;
};

export const string = (string) => {
  return typeof string === 'string';
};

export const verifyHourFormat = (string) => {
  if (string.length !== 5) {
    return false;
  }
  if (isNaN(parseInt(string.substring(0, 2)))) {
    return false;
  }
  if (isNaN(parseInt(string.substring(3, 5)))) {
    return false;
  }
  if (parseInt(string.substring(0, 2)) > 24) {
    return false;
  }
  if (parseInt(string.substring(3, 5)) > 59) {
    return false;
  }
  return true;
};

export const validateNotNullOrUndefined = (arg) => {
  if (typeof arg === 'undefined') {
    return false;
  }
  if (arg === null) {
    return false;
  }
  return true;
};

export const validateNotNullOrUndefinedandString = (arg) => {
  if(typeof arg === 'number'){
    return false
  }
  if (typeof arg?.trim() === 'undefined' || typeof arg?.trim() === null) {
    return true;
  }
  return true;
}

export const validateDateDDMMYYYY = (arg) => {
  const regularExpresion =
    /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/;
  const passwordValid1 = regularExpresion.test(arg);
  return passwordValid1;
};

export const validateDateRegx = (arg) => {
  const regularExpresion = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
  const passwordValid1 = regularExpresion.test(arg);
  return passwordValid1;
};

export const validateRegx = (arg, regx) => {
  const regx1 = regx;
  const passwordValid1 = regx1.test(arg);
  return passwordValid1;
};

// mobile number validation
export const validateText = (arg) => {
  const valid = arg.length > 1;
  return valid;
};
function cleanRut(rut) {
  return typeof rut === 'string'
    ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
    : '';
}
const validateRutNumber = (rut) => {
  if (typeof rut !== 'string') {
    return false;
  }
  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return false;
  }

  rut = cleanRut(rut);

  var t = parseInt(rut.slice(0, -1), 10);
  var m = 0;
  var s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }

  var v = s > 0 ? '' + (s - 1) : 'K';
  return v === rut.slice(-1);
};
// rut number validation
export const validateRut = (arg) => {
  const regularRut1 = /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/;
  const regularRut2 = /^\d{7,8}[-][0-9kK]{1}$/;
  const regularRut3 = /^\d{7,8}[0-9kK]{1}$/;
  const pass = validateRutNumber(arg);
  return (
    (regularRut1.test(arg) || regularRut2.test(arg) || regularRut3.test(arg)) &&
    pass
  );
};
// validate number
export const validateMobile = (arg) => {
  const regular = /^\d{8,11}$/;
  const mobileValid = regular.test(arg);
  return mobileValid;
};
// validate year
export const validateYear = (arg) => {
  const regular = /^\d{4}$/;
  const yearValid = regular.test(arg);
  return yearValid;
};

// validate patente
export const validatePlate = (arg) => {
  const regular = /^[a-zA-Z]+[0-9]{2,4}$/;
  return regular.test(arg);
};

// email validation
export const validateEmail = (arg) => {
  if (arg.length < 4 || !isNotNullOrUndefined(arg)) {
    return true;
  }
  const re =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(arg);
};
// password validation
export const validatePassword = (password) => {
  const regx1 = /^([a-zA-Z0-9@*#]{6,15})$/;
  const regx2 =
    /(?=^.{6,10}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
  const passwordValid1 = password.length > 0;
  const passwordValid2 = regx1.test(password);
  const passwordValid3 = regx2.test(password);
  return passwordValid1 && passwordValid2 && passwordValid3;
};

export const isNotNullOrUndefined = (arg) => {
  if (typeof arg === 'undefined') {
    return false;
  }
  if (arg === null) {
    return false;
  }
  return true;
};
