import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {auth} from '../services/firebase';
import {
  RESETTING_PASSWORD,
  SIGN_IN_USER,
  SIGN_IN_USER_AUTOMATIC,
  SIGN_IN_USER_SUCCESS,
  SIGN_OUT_USER,
} from '../../shared/constants/ActionTypes';
import {
  userSignInSuccess,
  userSignInFailed,
  userSignOutFailed,
  resettingPasswordFailed,
  resettingPasswordSuccess,
} from '../actions/auth';
import {
  loadCollectionQueryFirestore,
  updateDocumentFirestore,
} from '../services/firebase/firestore';
import Cookies from 'universal-cookie';
import {httpsPostFetchGoogleApi} from '../services/https/post';
import {isMobile} from 'react-device-detect';
import {
  resetPasswordFirebase,
  signOutRequest,
  setPersistenceSignIn,
  signInUserWithEmailPasswordRequest,
} from '../services/firebase/auth';
import {initialUrlPage, loadUser} from './authFunctions/functions';
import {history} from '../../redux/store';

const cookies = new Cookies();

function* loadDataPlatform(company, idUser) {
  try {
    yield put({
      type: 'LOAD_NOTIFICATIONS',
      payload: {
        path: 'mainData/' + company + '/notifications',
        idName: 'idNotifications',
        conditions: [
          {
            fieldPath: 'read',
            opt: '==',
            value: false,
          },
          {
            fieldPath: 'user',
            opt: '==',
            value: idUser,
          },
        ],
      },
    });
    // trabajando en carga de todos los componentes
    yield put({
      type: 'LOAD_LIST_SHIFT',
      payload: {
        path: 'mainData/' + company + '/shifts/',
        idName: 'idShift',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_BRANCH',
      payload: {
        path: company + '/listBranch',
        idName: 'idBranch',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_SUPPLIER',
      payload: {
        path: company + '/suppliers',
        idName: 'idSupplier',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_SUPPLIER_MATERIALS',
      payload: {
        path: company + '/supplierMaterials',
        idName: 'idSupplierMaterial',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_RESERVE_TIME',
      payload: {
        path: company + '/listReserveTime',
        idName: 'idTypeReserveTime',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_OPERATIONAL_LOST',
      payload: {
        path: company + '/listOperationalLost',
        idName: 'idOperationalLost',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_SPECIALITYS',
      payload: {
        path: company + '/listSpeciality',
        idName: 'idSpeciality',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_UNIT_MEASUREMENT',
      payload: {
        path: company + '/listUnitMeasurement',
        idName: 'idUnitMeasurement',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_TYPE_HOLIDAYS',
      payload: {
        path: company + '/listTypeHolidays',
        idName: 'idTypeHoliday',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_TYPE_WORK',
      payload: {
        path: company + '/listTypeWork',
        idName: 'idTypeWork',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_POSITION',
      payload: {
        path: company + '/listPosition',
        idName: 'idPosition',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_TYPE_VEHICLES',
      payload: {
        path: company + '/listTypeVehicles',
        idName: 'idTypeVehicle',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_BRAND',
      payload: {
        path: company + '/listBrands',
        idName: 'idBrand',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_MODELS',
      payload: {
        path: company + '/listModels',
        idName: 'idModel',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_FIREBASE_DATABASE_LIST_TYPES_USERS',
      payload: {
        path: 'usersTypes',
        idName: 'idTypeUser',
        conditions: [],
      },
    });
    // usual used
    yield put({
      type: 'LOAD_FLEETS',
      payload: {
        path: 'mainData/' + company + '/fleets',
        idName: 'idFleet',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_VEHICLES',
      payload: {
        path: 'mainData/' + company + '/vehicles',
        idName: 'idVehicle',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_SPARE_PARTS',
      payload: {
        path: 'mainData/' + company + '/spareParts',
        idName: 'idSparePart',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_MATERIALS',
      payload: {
        path: 'mainData/' + company + '/materials',
        idName: 'idMaterial',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_LIST_GROUPS',
      payload: {
        path: 'mainData/' + company + '/groups',
        idName: 'idGroup',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_OPERATORS_USERS',
      payload: {
        path: 'mainData/' + company + '/operatorsUsers',
        idName: 'idOperatorUser',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_MAINTENANCE_STAFF_USERS',
      payload: {
        path: 'mainData/' + company + '/maintenanceStaffUsers',
        idName: 'idMaintenanceStaffUser',
        conditions: [],
      },
    });
    yield put({
      type: 'LOAD_ADMINISTRATIVE_USERS',
      payload: {
        path: 'mainData/' + company + '/administrativeUsers',
        idName: 'idAdministrativeUser',
        conditions: [],
      },
    });
  } catch (e) {
    console.log('error load info plataform');
  }
}

function* successSignInUser({payload}) {
  const {company, deviceId, idUser, dataMobil, dataWeb} = payload;
  try {
    const pathCompany = 'mainData/' + company + '/companyInformation';
    const companyChannel = loadCollectionQueryFirestore(
      pathCompany,
      'idCompany',
      [],
    );
    yield loadDataPlatform(company, idUser);
    // todos los compoenentes de settings
    yield takeEvery(companyChannel, function* (action) {
      const companyData = action.data;
      const actionName = 'COMPANY_INFO_LOAD';
      yield put({
        type: actionName,
        payload: companyData,
      });
    });
    //
    let out = false;
    if (deviceId) {
      if (isMobile) {
        if (dataMobil) {
          if (dataMobil !== deviceId) {
            out = true;
          }
        } else {
          yield call(updateDocumentFirestore, 'users', idUser, {
            dataMobil: deviceId,
          });
        }
      } else {
        if (dataWeb) {
          if (dataWeb !== deviceId) {
            out = true;
          }
        } else {
          yield call(updateDocumentFirestore, 'users', idUser, {
            dataWeb: deviceId,
          });
        }
        if (out) {
          yield call(signOutRequest);
          const actionName = 'SIGN_OUT_USER_SUCCESS';
          yield put({type: actionName});
        }
      }
    }
    yield call(forwardTo, initialUrlPage);
  } catch (e) {
    console.log(e, 'error autenticacion success');
  }
}

function forwardTo(location) {
  history.push(location);
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    let {rememberMeCookie} = cookies.getAll();
    rememberMeCookie = rememberMeCookie === 'true';
    let signInUser = null;
    if (rememberMeCookie) {
      signInUser = yield call(setPersistenceSignIn, email, password);
    } else {
      signInUser = yield call(
        signInUserWithEmailPasswordRequest,
        email,
        password,
      );
    }
    if (!signInUser.user) {
      const actionName = 'SIGN_IN_USER_FAILED';
      const translateError = yield call(
        httpsPostFetchGoogleApi,
        signInUser.message,
      );
      yield put({
        type: actionName,
        payload: translateError,
      });
    }
    if (signInUser.user) {
      const userId = signInUser.user.uid;
      const USER_DATA = yield loadUser(userId);
      if (USER_DATA) {
        yield put(userSignInSuccess(USER_DATA));
      }
    }
  } catch (error) {
    console.log('autenticacion', error);
    const actionName = 'SIGN_IN_USER_FAILED';
    const translateError = yield call(httpsPostFetchGoogleApi, error);
    yield put({
      type: actionName,
      payload: translateError,
    });
  }
}

function* loadUserDataAutomatic({payload}) {
  const {uid} = payload;
  try {
    const USER_DATA = yield loadUser(uid);
    if (USER_DATA) {
      yield put(userSignInSuccess(USER_DATA));
    }
  } catch (error) {
    console.log('autenticacion', error);
    yield put(userSignInFailed());
    const actionName = 'SIGN_IN_USER_FAILED';
    const translateError = yield call(httpsPostFetchGoogleApi, error);
    yield put({
      type: actionName,
      payload: translateError,
    });
  }
}

function* signOut() {
  try {
    const userId = auth.currentUser.uid;
    yield call(signOutRequest);
    yield put({type: 'STOP_LOAD_EVERYTHING'});
    if (isMobile) {
      yield call(updateDocumentFirestore, 'users', userId, {
        dataWeb: null,
      });
    } else {
      yield call(updateDocumentFirestore, 'users', userId, {
        dataWeb: null,
      });
    }
    const actionName = 'SIGN_OUT_USER_SUCCESS';
    yield put({type: actionName});
  } catch (error) {
    yield put(userSignOutFailed());
  }
}

function* resettingPasswordUser(info) {
  const {payload} = info;
  try {
    yield call(resetPasswordFirebase, payload.email);
    yield put(resettingPasswordSuccess());
  } catch (error) {
    yield put(resettingPasswordFailed());
  }
}

export function* resettingPassword() {
  yield takeEvery(RESETTING_PASSWORD, resettingPasswordUser);
}

export function* signInUser() {
  yield takeEvery(SIGN_IN_USER, signInUserWithEmailPassword);
}

export function* signInUserAutomatic() {
  yield takeEvery(SIGN_IN_USER_AUTOMATIC, loadUserDataAutomatic);
}

export function* signOutUser() {
  yield takeEvery(SIGN_OUT_USER, signOut);
}

export function* signInUserSuccess() {
  yield takeEvery(SIGN_IN_USER_SUCCESS, successSignInUser);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(resettingPassword),
    fork(signInUserAutomatic),
    fork(signInUserSuccess),
  ]);
}
