import moment from 'moment';

const filterActivities = (activities, vehicles, auth) => {
  const now = moment();
  const formattedDate = now.format('DD/MM/YYYY');
  const dataStorage = JSON.parse(
    localStorage.getItem('notificationMaintenance'),
  );
  let badge = true;
  if (
    dataStorage?.date === formattedDate &&
    dataStorage?.user === auth?.user?.idUser
  ) {
    badge = false;
  }

  const activitiesFilter = activities?.filter((item) => {
    const vehicleDetail = vehicles?.filter(
      (vehicle) => vehicle.idVehicle === item.vehicle,
    )[0];
    return vehicleDetail?.active && vehicleDetail?.state?.alta;
  });

  return {
    list: activitiesFilter?.filter(
      (item) =>
        !item.fromOt &&
        !(
          item.status === 'En Aprobación' ||
          item.status === 'En Aprobacion' ||
          item.status === 'Cerrada'
        ) &&
        item.branch === auth?.user?.branchSelected?.idBranch,
    ),
    badge,
  };
};

export default filterActivities;
