import {put, call, select} from '@redux-saga/core/effects';
import {
  httpsPostFetch,
  httpsPostFetchGoogleApi,
  responseBody,
} from '../services/https/post';

const getAuth = (state) => state.auth;

export const operationDeleteHttpsPost = {
  name: 'HTTPS_POST_DELETE',
  reducer: {
    initialState: {deleting: false, deleted: false, deleteFailed: false},
    cases: [
      {
        name: 'HTTPS_POST_DELETE_',
        newState: {deleting: true, deleted: false, deleteFailed: false},
      },
      {
        name: 'HTTPS_POST_DELETED_',
        newState: {deleting: false, deleted: true, deleteFailed: false},
      },
      {
        name: 'HTTPS_POST_DELETE_FAILED_',
        newState: {deleting: false, deleted: false, deleteFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        sendDeleteHttpsPost: (payload) => {
          return {type: 'HTTPS_POST_DELETE_' + name, payload: payload};
        },
        successDeleteHttpsPost: () => {
          return {type: 'HTTPS_POST_DELETED_' + name};
        },
        failDeleteHttpsPost: (payload) => {
          return {type: 'HTTPS_POST_DELETE_FAILED_' + name, payload: payload};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'HTTPS_POST_DELETE_',
    function: (name) => {
      return function* sendDeleteHttpsPost({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, dataCreate, company} = payload;
          let data = {...dataCreate};
          const auth = yield select(getAuth);
          if (
            auth.user.branchSelected &&
            auth.user.branchSelected !== 'todos'
          ) {
            data = {
              ...dataCreate,
              branch: auth.user.branchSelected.idBranch,
            };
          }
          const response = yield call(httpsPostFetch, path, data, company);
          if (response.status === 200) {
            yield put({type: 'DELETE_SUCCESS'});
          } else {
            const responseBody2 = yield call(responseBody, response);
            const translateError = yield call(
              httpsPostFetchGoogleApi,
              responseBody2.message,
            );
            yield put({
              type: 'DELETE_FAILED',
              payload: {dataLoad: {errorMessage: translateError}},
            });
          }
        } catch (e) {
          yield put({
            type: 'DELETE_FAILED',
            payload: {dataLoad: {errorMessage: ''}},
          });
          console.log('error https post' + name, e);
        }
      };
    },
  },
};
