import firebase from 'firebase/compat';
import 'firebase/firestore';
import {configTestApp} from './setting';

// Initialize Firebase
const config = configTestApp;

firebase.initializeApp(config);
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const database = firebase.database();
export {auth, firestore, database, storage};
