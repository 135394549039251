import React from 'react';

export const fleetFleetsPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/fleet-fleet',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/fleet-vehicles',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/fleet-systems',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/fleet-components',
        component: React.lazy(() => import('./Page')),
      },
    ],
  },
];
