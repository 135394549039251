import firebase from 'firebase/compat';
import {auth} from './index';

export const resetPasswordFirebase = async (email) =>
  await auth
    .sendPasswordResetEmail(email)
    .then((res) => res)
    .catch((error) => console.log('error resert', error));

export const signInUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .setPersistence(firebase.auth.Auth.Persistence.NONE)
    .then(() => {
      return auth
        .signInWithEmailAndPassword(email, password)
        .then((authUser) => authUser)
        .catch((error) => console.log('errr enter', error));
    })
    .catch(function (error) {
      console.log('failed to ser persistence: ' + error.message);
    });

export const setPersistenceSignIn = async (email, password) =>
  await auth
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      return auth
        .signInWithEmailAndPassword(email, password)
        .then((authUser) => authUser)
        .catch((error) => error);
    })
    .catch(function (error) {
      console.log('failed to ser persistence: ' + error.message);
    });

export const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => {
      console.log('SIGN OUT');
      return authUser;
    })
    .catch((error) => error);
