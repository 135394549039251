import {operationCreate} from './create';
import {operationCreateWithFiles} from './createWithFiles';
import {operationDelete} from './delete';
import {operationDeleteHttpsPost} from './deleteHttpsPost';
import {operationUpdate} from './update';
import {operationUpdateLocalState} from './updateLocalState';
import {operationLoad} from './loadCollection';
import {operationLoadCollectionGroup} from './loadCollectionGroup';
import {operationLoadFirebaseDatabase} from './loadFirebaseDatabase';
import {operationCreateFirebaseDatabase} from './createFirebaseDatabase';
import {operationDeleteFirebaseDatabase} from './deleteFirebaseDatabase';
import {operationUpdateFirebaseDatabase} from './updateFirebaseDatabase';
import {operationHttpsPost} from './httpsPost';
import {operationHttpsPostWithResponse} from './httpsPostWithResponse';
import {operationHttpsPostWithFiles} from './httpsPostWithFiles';
import {operationCreateWithOutBranch} from './createWithOutBranch';
import {operationLoadWithOutBranch} from './loadWithOutBranch';
import {operationDeleteFile} from './deleteFile';
import {operationSetFile} from './setFile';
import {operationDeleteFileDocument} from './deleteFileDocument';
import {operationUpdateFileDocument} from './updateFileDocument';
import {operationLoadLimitToOne} from './loadCollectionLimitToOneOrder';

export const listOperations = [
  operationSetFile,
  operationDeleteFile,
  operationCreate,
  operationCreateWithFiles,
  operationDelete,
  operationDeleteHttpsPost,
  operationUpdate,
  operationUpdateLocalState,
  operationLoad,
  operationLoadLimitToOne,
  operationLoadCollectionGroup,
  operationLoadFirebaseDatabase,
  operationCreateFirebaseDatabase,
  operationDeleteFirebaseDatabase,
  operationUpdateFirebaseDatabase,
  operationHttpsPost,
  operationHttpsPostWithResponse,
  operationHttpsPostWithFiles,
  operationCreateWithOutBranch,
  operationLoadWithOutBranch,
  operationDeleteFileDocument,
  operationUpdateFileDocument,
];
