import {put, call} from '@redux-saga/core/effects';
import {uploadFileWithStorage} from '../services/firebase/firestore';

export const operationSetFile = {
  name: 'SET_FILE',
  reducer: {
    initialState: {setting: false, set: false, setFailed: false},
    cases: [
      {
        name: 'SETTING_FILE_',
        newState: {setting: true, set: false, setFailed: false},
      },
      {
        name: 'SET_FILE_',
        newState: {setting: false, set: true, setFailed: false},
      },
      {
        name: 'SET_FILE_FAILED_',
        newState: {setting: false, set: false, setFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        settingFile: (payload) => {
          return {type: 'SETTING_FILE_' + name, payload: payload};
        },
        setFile: () => {
          return {type: 'SET_FILE_' + name};
        },
        setFileFailed: () => {
          return {type: 'SET_FILE_FAILED_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'SETTING_FILE_',
    function: (name) => {
      return function* setWithFile({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, file} = payload;
          yield call(uploadFileWithStorage, file, path);
          const actionName = 'SET_FILE_' + name;
          yield put({type: actionName});
          yield put({type: 'SEND_SUCCESS'});
        } catch (e) {
          console.log('set file', e);
          const actionName = 'SET_FILE_FAILED_' + name;
          yield put({type: actionName});
          yield put({type: 'SEND_FAILED'});
        }
      };
    },
  },
};
