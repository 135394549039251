import React from 'react';

export const settingPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/supplier',
        component: React.lazy(() => import('./Pages/supplier')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/supplier-materials',
        component: React.lazy(() => import('./Pages/supplierMaterials')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-groups',
        component: React.lazy(() => import('./Pages/listGroup')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/branch',
        component: React.lazy(() => import('./Pages/branch')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-reserve-time',
        component: React.lazy(() => import('./Pages/listReserveTime')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-operational-lost',
        component: React.lazy(() => import('./Pages/listOperationalLost')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-shift',
        component: React.lazy(() => import('./Pages/listShift')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-type-users',
        component: React.lazy(() => import('./Pages/listProfileUser')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-specialitys',
        component: React.lazy(() =>
          import('./Pages/listSpecialitysTechnicians'),
        ),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-unit-measurements',
        component: React.lazy(() => import('./Pages/listUnitMeasurement')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-type-holidays',
        component: React.lazy(() => import('./Pages/listNon-ProductionDays')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-type-work',
        component: React.lazy(() => import('./Pages/listTypeWorkWithOutOt')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/list-positions',
        component: React.lazy(() => import('./Pages/listPositions')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/setting/vehicles',
        component: React.lazy(() => import('./Pages/vehicles')),
      },
    ],
  },
];
