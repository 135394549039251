import React from 'react';

export const EmptyPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/empty',
        component: React.lazy(() => import('./Page')),
      },
    ],
  },
];
