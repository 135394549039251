import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const AppLogoSmartphone = () => {
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 45,
      width: 45,
      marginRight: 10,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <Hidden lgUp>
        <img
          className={classes.logo}
          src={'/assets/images/icono-logo.png'}
          alt='maint-data-logo'
        />
      </Hidden>
    </Box>
  );
};

export default AppLogoSmartphone;
