import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {ListItem, makeStyles} from '@material-ui/core';
import {Fonts} from '../../../shared/constants/AppEnums';
import clsx from 'clsx';
import {dateToFormat3} from '../../../AllestStudio/forms/functions/utils';

const useStyles = makeStyles((theme) => ({
  textBase: {
    fontSize: 14,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  minWidth0: {
    minWidth: 0,
  },
  listItemRoot: {
    padding: '8px 20px',
  },
}));

const searchVehicle = (list, vehicle) => {
  const veh = list.find((item) => item.idVehicle === vehicle);
  return `Equipo: ${veh?.internalNumber} `;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const NotificationItem = (props) => {
  const {item} = props;
  const classes = useStyles(props);

  return (
    <ListItem className={clsx(classes.listItemRoot, 'item-hover')}>
      <Box
        component='p'
        className={classes.textBase}
        color='text.secondary'
        style={{fontSize: 12}}>
        <Box
          mr={2}
          component='span'
          display='inline-block'
          color='text.primary'
          fontWeight={Fonts.MEDIUM}>
          {searchVehicle(props.vehicles, item.vehicle)}
        </Box>
        {capitalizeFirstLetter(item.activity)}
      </Box>
    </ListItem>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
