import {eventChannel} from 'redux-saga';
import {firestore, storage} from './index';
import { cond } from 'lodash';

// coleccion
const collectionArray = (snapshot, idName) => {
  const collection = [];
  snapshot.forEach((change) => {
    const document = {...change.data()};
    document[idName] = change.id;
    collection.push(document);
  });
  return collection;
};

export const loadCollectionQueryFirestore = (
  collectionPath,
  idName,
  conditions,
  company,
) =>
  eventChannel((emitter) => {
    let firestoreReference = firestore.collection(collectionPath);
    conditions.forEach((condition) => {
      firestoreReference = firestoreReference.where(
        condition.fieldPath,
        condition.opt,
        condition.value,
      );
    });
    if(company == 'WjVkXyA8hVwlpLyGGGul'){
      firestoreReference = firestoreReference.limit(1500);
    }
    const unsubscribe = firestoreReference.onSnapshot((snapshot) => {
      const COLLECTION = collectionArray(snapshot, idName);
      emitter({
        data: COLLECTION,
      });
    });
    return () => unsubscribe();
  });

export const loadCollectionQueryLimitToOneFirestore = (
  collectionPath,
  idName,
  conditions,
  field,
  order,
) =>
  eventChannel((emitter) => {
    let firestoreReference = firestore.collection(collectionPath);
    conditions.forEach((condition) => {
      firestoreReference = firestoreReference.where(
        condition.fieldPath,
        condition.opt,
        condition.value,
      );
    });
    firestoreReference = firestoreReference.orderBy(field, order).limit(1);
    const unsubscribe = firestoreReference.onSnapshot((snapshot) => {
      const COLLECTION = collectionArray(snapshot, idName);
      emitter({
        data: COLLECTION,
      });
    });
    return () => unsubscribe();
  });

export const loadCollectionGroupFirestore = (
  collectionPath,
  idName,
  conditions,
) =>
  eventChannel((emitter) => {
    let firestoreReference = firestore.collectionGroup(collectionPath);
    conditions.forEach((condition) => {
      firestoreReference = firestoreReference.where(
        condition.fieldPath,
        condition.opt,
        condition.value,
      );
    });
    const unsubscribe = firestoreReference.onSnapshot((snapshot) => {
      const COLLECTION = collectionArray(snapshot, idName);
      emitter({
        data: COLLECTION,
      });
    });
    return () => unsubscribe();
  });

// files

export const uploadFileWithStorage = async (file, path) => {
  const refPhoto = '' + path;
  if (file) {
    const ref = storage.ref().child(refPhoto);
    await ref
      .put(file)
      .then(function () {
        console.log('File successfully uploaded!');
      })
      .catch(function (error) {
        console.error('Error upload file: ', error);
      });
  } else {
    console.log('error de carga: foto cargas 1');
  }
};

export const deleteFileWithStorage = async (path) => {
  const refPhoto = '' + path;
  const ref = storage.ref().child(refPhoto);
  await ref
    .delete()
    .then(function () {
      console.log('File successfully uploaded!');
    })
    .catch(function (error) {
      console.error('Error upload file: ', error);
    });
};

// document
// documentos
export const getDocumentFirestore = (collectionPath, documentPath, idName) =>
  eventChannel((emitter) => {
    const unsubscribe = firestore
      .collection(collectionPath)
      .doc(documentPath)
      .onSnapshot((doc) => {
        if (doc.exists) {
          if (idName) {
            const document = {...doc.data()};
            document[idName] = doc.id;
            emitter({
              data: document,
            });
          } else {
            emitter({
              data: doc.data(),
            });
          }
        } else {
          // doc.data() will be undefined in this case
          emitter({
            data: {cambiado: false},
          });
        }
      });
    return () => unsubscribe();
  });

export const getDocumentFirestorePromise = async (documentPath, idDocument) => {
  return await firestore.collection(documentPath).doc(idDocument).get();
};

export const setDocumentFirestore = async (documentPath, document) => {
  return await firestore.collection(documentPath).doc().set(document);
};

export const setDocumentFirestoreWithId = async (path, document, id) => {
  return await firestore.collection(path).doc(id).set(document);
};

export const deleteDocumentFirestore = async (documentPath, idDocument) => {
  return await firestore
    .collection(documentPath)
    .doc(idDocument)
    .update({active: false});
};

export const updateDocumentFirestore = async (
  documentPath,
  idDocument,
  dataDocument,
) => {
  return await firestore
    .collection(documentPath)
    .doc(idDocument)
    .update(dataDocument);
};
