import {put, take, takeEvery} from '@redux-saga/core/effects';
import {loadDatabase} from '../services/firebase/database';

export const operationLoadFirebaseDatabase = {
  name: 'LOAD_FIREBASE_DATABASE',
  reducer: {
    initialState: {
      loadingFirebaseDatabase: false,
      loadedFirebaseDatabase: false,
      loadFailedFirebaseDatabase: false,
    },
    cases: [
      {
        name: 'LOAD_FIREBASE_DATABASE_',
        newState: {
          loadingFirebaseDatabase: true,
          loadedFirebaseDatabase: false,
          loadFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'LOADED_FIREBASE_DATABASE_',
        newState: {
          loadingFirebaseDatabase: false,
          loadedFirebaseDatabase: true,
          loadFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'LOAD_FAILED_FIREBASE_DATABASE_',
        newState: {
          loadingFirebaseDatabase: false,
          loadedFirebaseDatabase: false,
          loadFailedFirebaseDatabase: true,
        },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        loadFirebaseDatabase: (payload) => {
          return {type: 'LOAD_FIREBASE_DATABASE_' + name, payload: payload};
        },
        loadedFirebaseDatabase: (payload) => {
          return {type: 'LOADED_FIREBASE_DATABASE_' + name, payload: payload};
        },
        loadFailedFirebaseDatabase: () => {
          return {type: 'LOAD_FAILED_FIREBASE_DATABASE_' + name};
        },
        stopLoadFirebaseDatabase: () => {
          return {type: 'STOP_LOAD_FIREBASE_DATABASE_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'LOAD_FIREBASE_DATABASE_',
    function: (name) => {
      return function* load({payload}) {
        yield put({type: 'LOAD_ALERT'});
        try {
          const {path, idName} = payload;
          const Channel = loadDatabase(path);
          yield takeEvery(Channel, function* (action) {
            const result = [];
            for (const property in action.data) {
              if (idName) {
                const a = {...action.data[property], [idName]: property};
                result.push(a);
              } else {
                result.push(action.data[property]);
              }
            }
            yield put({
              type: 'LOADED_FIREBASE_DATABASE_' + name,
              payload: {dataLoad: {listFirebaseDatabase: result}},
            });
            yield put({
              type: 'LOADED_ALERT',
            });
          });
          yield take([
            'STOP_LOAD_FIREBASE_DATABASE_' + name,
            'LOAD_FIREBASE_DATABASE_' + name,
            'STOP_LOAD_EVERYTHING',
          ]);
          Channel.close();
          yield put({
            type: 'LOADED_FIREBASE_DATABASE_' + name,
            payload: {dataLoad: {listFirebaseDatabase: []}},
          });
        } catch (e) {
          console.log(e);
          yield put({
            type: 'LOADED_FIREBASE_DATABASE_' + name,
            payload: {dataLoad: {listFirebaseDatabase: []}},
          });
          yield put({
            type: 'LOADED_ALERT',
          });
        }
      };
    },
  },
};
