import {isNotNullOrUndefined} from '../../../shared/functions/validations';

export const formatInputText = (data, input) => {
  let text = input;
  if (data.money) {
    const a = input.toString().replace('$', '');
    const b = a.toString().replace('.', '');
    const c = b.replace(/[^ 0-9]/g, '');
    const number = parseInt(c) ? parseInt(c) : 0;
    text = '$' + number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  if (data.number) {
    const b = input.toString().replaceAll('.', '');
    const c = b.replace(/[^ 0-9]/g, '');
    const number = parseInt(c) ? parseInt(c) : 0;
    text = number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  if (data.rut) {
    const newRut = input
      .replace(/\./g, '')
      .replace(/\-/g, '')
      .trim()
      .toLowerCase();
    const lastDigit = newRut.substring(newRut.length - 1, newRut.length);
    let rutDigit = newRut.substring(0, newRut.length - 1);
    let format = '';
    for (let i = rutDigit.length; i > -2; i = i - 3) {
      const a = i - 3 > 0 ? i - 3 : 0;
      const tres = (a > 0 ? '.' : '') + rutDigit.substring(a, i);
      format = tres.concat(format);
    }
    text = format.concat('-').concat(lastDigit);
  }
  return text;
};

export const formatInputTextSpecialMathOperation = (data, input) => {
  if (!isNotNullOrUndefined(input) || !isNotNullOrUndefined(data)) {
    return '';
  }
  let text = input;
  if (data.money) {
    text = '$' + input.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  if (data.number) {
    text = input.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  return text;
};
