import React from 'react';

export const operationsPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/operations',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/operations-oil',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/operations-no-production-days',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/operations-waste-operational',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/operations-times-reserves',
        component: React.lazy(() => import('./Page')),
      },
    ],
  },
];
