import {put, call} from '@redux-saga/core/effects';
import {setDatabase} from '../services/firebase/database';

export const operationCreateFirebaseDatabase = {
  name: 'CREATE_FIREBASE_DATABASE',
  reducer: {
    initialState: {
      creatingFirebaseDatabase: false,
      createdFirebaseDatabase: false,
      createFailedFirebaseDatabase: false,
    },
    cases: [
      {
        name: 'CREATE_FIREBASE_DATABASE_',
        newState: {
          creatingFirebaseDatabase: true,
          createdFirebaseDatabase: false,
          createFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'CREATED_FIREBASE_DATABASE_',
        newState: {
          creatingFirebaseDatabase: false,
          createdFirebaseDatabase: true,
          createFailedFirebaseDatabase: false,
        },
      },
      {
        name: 'CREATE_FAILED_FIREBASE_DATABASE_',
        newState: {
          creatingFirebaseDatabase: false,
          createdFirebaseDatabase: false,
          createFailedFirebaseDatabase: true,
        },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        createFirebaseDatabase: (payload) => {
          return {type: 'CREATE_FIREBASE_DATABASE_' + name, payload: payload};
        },
        createdFirebaseDatabase: () => {
          return {type: 'CREATED_FIREBASE_DATABASE_' + name};
        },
        createFailedFirebaseDatabase: () => {
          return {type: 'CREATE_FAILED_FIREBASE_DATABASE_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'CREATE_FIREBASE_DATABASE_',
    function: (name) => {
      return function* create({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, dataCreate} = payload;
          yield call(setDatabase, path, dataCreate);
          yield put({type: 'CREATED_SUCCESS'});
          yield put({type: 'CREATED_FIREBASE_DATABASE_' + name});
        } catch (e) {
          console.log('error al crear en database', e);
          yield put({type: 'CREATED_FAILED'});
        }
      };
    },
  },
};
