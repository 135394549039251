import {put, call} from '@redux-saga/core/effects';
import {deleteFileWithStorage} from '../services/firebase/firestore';

export const operationDeleteFile = {
  name: 'DELETE_FILE',
  reducer: {
    initialState: {deleting: false, deleted: false, deleteFailed: false},
    cases: [
      {
        name: 'DELETE_FILE_',
        newState: {deleting: true, deleted: false, deleteFailed: false},
      },
      {
        name: 'DELETED_FILE_',
        newState: {deleting: false, deleted: true, deleteFailed: false},
      },
      {
        name: 'DELETE_FILE_FAILED_',
        newState: {deleting: false, deleted: false, deleteFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        deleteFile: (payload) => {
          return {type: 'DELETE_FILE_' + name, payload: payload};
        },
        deletedFile: () => {
          return {type: 'DELETED_FILE_' + name};
        },
        deleteFileFailed: () => {
          return {type: 'DELETE_FILE_FAILED_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'DELETE_FILE_',
    function: (name) => {
      return function* deleteWithFile({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path} = payload;
          yield call(deleteFileWithStorage, path);
          yield put({type: 'DELETE_SUCCESS'});
        } catch (e) {
          console.log(e);
          yield put({type: 'DELETE_FAILED'});
        }
      };
    },
  },
};
