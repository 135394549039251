const generateUniqueId = require('generate-unique-id');

export const generateUidJustNumber = () => {
  return generateUniqueId({
    length: 20,
    useLetters: true,
    useNumbers: true,
  });
};

export const generateUid = () => {
  return generateUniqueId({
    length: 20,
    useLetters: true,
    useNumbers: true,
  });
};
