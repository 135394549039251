import {put, take, takeEvery} from '@redux-saga/core/effects';
import {loadCollectionGroupFirestore} from '../services/firebase/firestore';

export const operationLoadCollectionGroup = {
  name: 'LOAD_COLLECTION_GROUP',
  reducer: {
    initialState: {loading: false, loaded: false, loadFailed: false},
    cases: [
      {
        name: 'LOAD_COLLECTION_GROUP_',
        newState: {loading: true, loaded: false, loadFailed: false},
      },
      {
        name: 'LOADED_COLLECTION_GROUP_',
        newState: {loading: false, loaded: true, loadFailed: false},
      },
      {
        name: 'LOAD_COLLECTION_GROUP_FAILED_',
        newState: {loading: false, loaded: false, loadFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        load: (payload) => {
          return {type: 'LOAD_COLLECTION_GROUP_' + name, payload: payload};
        },
        loaded: (payload) => {
          return {type: 'LOADED_COLLECTION_GROUP_' + name, payload: payload};
        },
        loadFailed: () => {
          return {type: 'LOAD_COLLECTION_GROUP_FAILED_' + name};
        },
        stopLoad: () => {
          return {type: 'STOP_LOAD_COLLECTION_GROUP_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'LOAD_COLLECTION_GROUP_',
    function: (name) => {
      return function* load({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, idName, conditions} = payload;
          const conditionsBase = conditions.concat([]);
          const Channel = loadCollectionGroupFirestore(
            path,
            idName,
            conditionsBase,
          );
          // console.log(conditionsBase, path);
          yield takeEvery(Channel, function* (action) {
            yield put({
              type: 'LOADED_COLLECTION_GROUP_' + name,
              payload: {dataLoad: {list: action.data}},
            });
            // console.log('Data cargada', action.data);
            yield put({
              type: 'LOADED_ALERT',
            });
          });
          yield take([
            'LOAD_COLLECTION_GROUP_' + name,
            'STOP_LOAD_COLLECTION_GROUP_' + name,
            'STOP_LOAD_EVERYTHING',
          ]);
          Channel.close();
          yield put({
            type: 'LOADED_COLLECTION_GROUP_' + name,
            payload: {dataLoad: {list: []}},
          });
        } catch (e) {
          console.log('Error', e);
        }
      };
    },
  },
};
