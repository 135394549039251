import {
  SIGN_IN_USER,
  SIGN_IN_USER_SUCCESS,
  SIGN_IN_USER_FAILED,
  SIGN_OUT_USER,
  SIGN_OUT_USER_SUCCESS,
  SIGN_OUT_USER_FAILED,
  RESETTING_PASSWORD,
  RESETTING_PASSWORD_SUCCESS,
  RESETTING_PASSWORD_FAILED,
  AUTH_CLEAN_STORE,
  AUTH_SING_IN_AUTOMATIC,
} from '../../shared/constants/ActionTypes';

export const userSignIn = (credentials) => {
  return {type: SIGN_IN_USER, payload: credentials};
};
export const userSignInSuccess = (user) => {
  return {type: SIGN_IN_USER_SUCCESS, payload: user};
};
export const userSignInFailed = (user) => {
  return {type: SIGN_IN_USER_FAILED, payload: user};
};
export const userSignOut = () => {
  return {type: SIGN_OUT_USER};
};
export const userSignOutSuccess = () => {
  return {type: SIGN_OUT_USER_SUCCESS};
};
export const userSignOutFailed = () => {
  return {type: SIGN_OUT_USER_FAILED};
};
export const resettingPassword = (email) => {
  return {type: RESETTING_PASSWORD, payload: email};
};
export const resettingPasswordSuccess = () => {
  return {type: RESETTING_PASSWORD_SUCCESS};
};
export const resettingPasswordFailed = () => {
  return {type: RESETTING_PASSWORD_FAILED};
};
export const authCleanStore = () => {
  return {type: AUTH_CLEAN_STORE};
};
export const authSignInAutomatic = (idUser) => {
  return {type: AUTH_SING_IN_AUTOMATIC, payload: idUser};
};
