import {put, call} from '@redux-saga/core/effects';
import {updateDocumentFirestore} from '../services/firebase/firestore';

export const operationUpdate = {
  name: 'UPDATE',
  reducer: {
    initialState: {updating: false, updated: false, updateFailed: false},
    cases: [
      {
        name: 'UPDATE_',
        newState: {updating: true, updated: false, updateFailed: false},
      },
      {
        name: 'UPDATED_',
        newState: {updating: false, updated: true, updateFailed: false},
      },
      {
        name: 'UPDATE_FAILED_',
        newState: {updating: false, updated: false, updateFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        update: (payload) => {
          return {type: 'UPDATE_' + name, payload: payload};
        },
        updated: () => {
          return {type: 'UPDATED_' + name};
        },
        updateFailed: () => {
          return {type: 'UPDATE_FAILED_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'UPDATE_',
    function: (name) => {
      return function* update({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, idDocument, dataDocument} = payload;
          yield call(updateDocumentFirestore, path, idDocument, dataDocument);
          yield put({type: 'SEND_SUCCESS'});
        } catch (e) {
          console.log('update', e);
          yield put({type: 'SEND_FAILED'});
        }
      };
    },
  },
};
