import moment from 'moment-timezone';
import {isNotNullOrUndefined} from './validations';

export const simpleTimer = (time) => {
  var promise = new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve();
    }, time);
  });
  return promise;
};

export const calculateDifferenceHours = (hourOne, hourTwo) => {
  const HoursOne = parseInt(hourOne.substring(0, 2));
  const HoursTwo = parseInt(hourTwo.substring(0, 2));
  const MinutesOne = parseInt(hourOne.substring(3, 5));
  const MinutesTwo = parseInt(hourTwo.substring(3, 5));
  let differenceMinutes = 0;
  let differenceHours = 0;
  if (MinutesTwo - MinutesOne < 0) {
    differenceMinutes = 60 - MinutesOne + MinutesTwo;
    differenceHours = -1;
  } else {
    differenceMinutes = MinutesTwo - MinutesOne;
  }
  if (HoursTwo - HoursOne < 0) {
    differenceHours = 24 - HoursOne + HoursTwo + differenceHours;
  } else {
    differenceHours = differenceHours + HoursTwo - HoursOne;
  }
  return (
    (differenceHours < 10 ? '0' + differenceHours : differenceHours) +
    ':' +
    (differenceMinutes < 10 ? '0' + differenceMinutes : differenceMinutes)
  );
};

export const calculateDifferenceHoursBetweenDates = (date1, date2) => {
  const hoursTotal = Math.trunc(Math.abs(date1 - date2) / 36e5);
  const minutes = Math.abs(Math.abs(date1 - date2) % 36e5) / 60000;
  const days = Math.trunc(Math.abs(hoursTotal / 24));
  const hours = hoursTotal % 24;
  const differenceString =
    'Días ' +
    days +
    ' Horas: ' +
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes);
  return differenceString;
};

export const calculateDifferenceHoursBetweenDatesReturnHours = (
  date1,
  date2,
) => {
  const hoursTotal = Math.abs(date1 - date2) / 36e5;
  return hoursTotal;
};

export const calculateSumHours = (hourAcumulate, newHour) => {
  const HoursOne = parseInt(hourAcumulate.substring(0, 2));
  const HoursTwo = parseInt(newHour.substring(0, 2));
  const MinutesOne = parseInt(hourAcumulate.substring(3, 5));
  const MinutesTwo = parseInt(newHour.substring(3, 5));
  let sumMinutes = 0;
  let sumHours = 0;
  if (MinutesTwo + MinutesOne > 60) {
    sumMinutes = MinutesOne + MinutesTwo - 60;
    sumHours = 1;
  } else {
    sumMinutes = MinutesTwo + MinutesOne;
  }
  sumHours = sumHours + HoursTwo + HoursOne;
  return (
    (sumHours < 10 ? '0' + sumHours : sumHours) +
    ':' +
    (sumMinutes < 10 ? '0' + sumMinutes : sumMinutes)
  );
};

export const numberToFormatHour = (number) => {
  const justHour = Math.trunc(number);
  const justMinutes = Math.trunc((number % 1) * 60);
  const hours = justHour > 9 ? justHour : '0' + justHour;
  const minutes = justMinutes > 9 ? justMinutes : '0' + justMinutes;
  return hours + ':' + minutes;
};

export const dateWithHourToFormatHour = (date) => {
  const justHour = parseInt(date.getHours());
  const justMinutes = parseInt(date.getMinutes());
  const hours = justHour > 9 ? justHour : '0' + justHour;
  const minutes = justMinutes > 9 ? justMinutes : '0' + justMinutes;
  return hours + ':' + minutes;
};

export const numberDaysFromDateToToday = (date) => {
  if (!isNotNullOrUndefined(date)) {
    return '-';
  }
  if (!isNotNullOrUndefined(date.toDate)) {
    return '-';
  }
  const pastDate = moment(date.toDate());
  const currentDate = moment();
  return currentDate.diff(pastDate, 'days') + ' días';
};

export const testMoment = () => {
  const momentOne = moment();
  const momentTwo = moment();
  const momentChile = momentOne.tz('America/Santiago');
};
