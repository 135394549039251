import {put, call} from '@redux-saga/core/effects';
import {deleteDocumentFirestore} from '../services/firebase/firestore';

export const operationDelete = {
  name: 'DELETE',
  reducer: {
    initialState: {deleting: false, deleted: false, deleteFailed: false},
    cases: [
      {
        name: 'DELETE_',
        newState: {deleting: true, deleted: false, deleteFailed: false},
      },
      {
        name: 'DELETED_',
        newState: {deleting: false, deleted: true, deleteFailed: false},
      },
      {
        name: 'DELETE_FAILED_',
        newState: {deleting: false, deleted: false, deleteFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        delete: (payload) => {
          return {type: 'DELETE_' + name, payload: payload};
        },
        deleted: () => {
          return {type: 'DELETED_' + name};
        },
        deleteFailed: () => {
          return {type: 'DELETE_FAILED_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'DELETE_',
    function: (name) => {
      return function* delet({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, idDocument} = payload;
          yield call(deleteDocumentFirestore, path, idDocument);
          yield put({type: 'DELETE_SUCCESS'});
        } catch (e) {
          yield put({type: 'DELETE_FAILED'});
        }
      };
    },
  },
};
