import React, {useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import BuildIcon from '@material-ui/icons/Build';
import {makeStyles, Popover} from '@material-ui/core';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Scrollbar from '../Scrollbar';
import IntlMessages from '../../utility/IntlMessages';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import NotificationItem from './NotificationItem';
import {Fonts} from '../../../shared/constants/AppEnums';
import {connect} from 'react-redux';
import {ActivitiesThisMonth} from '../../../redux/builder';
import moment from 'moment';
import filterActivities from './utils/filterActivities';

const activitiesThisMonthActions = ActivitiesThisMonth.getActions();

const useStyles = makeStyles((theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
      [theme.breakpoints.up('xl')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 200,
      [theme.breakpoints.up('xl')]: {
        maxHeight: 380,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      width: 'auto',
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
}));

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    auth: state.auth,
    activitiesThisMonth: state.activitiesThisMonth,
    fleets: state.fleets,
    vehicles: state.vehicles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadActivitiesProps: (company, conditions) =>
    dispatch(
      activitiesThisMonthActions.load({
        path: 'mainData/' + company + '/activities',
        idName: 'idActivity',
        conditions: conditions,
      }),
    ),
  stopLoadActivitiesProps: () =>
    dispatch(activitiesThisMonthActions.stopLoad()),
});

const NotificationsPlanificacion = (props) => {
  const [anchorNotification, setAnchorNotification] = React.useState(null);

  const onClickNotificationButton = (event) => {
    setAnchorNotification(event.currentTarget);
  };

  const compareByDate = (a, b) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  };

  useEffect(() => {
    const startOfDay = moment().startOf('day');
    const endOfDay = moment().endOf('day');
    const dateArray = [
      {
        fieldPath: 'dateActivity',
        opt: '>=',
        value: startOfDay.toDate(),
      },
      {
        fieldPath: 'dateActivity',
        opt: '<',
        value: endOfDay.toDate(),
      },
    ];
    props.loadActivitiesProps(props.auth.user.company, dateArray);
  }, [props.auth]);

  const classes = useStyles(props);

  return (
    <>
      <IconButton
        className={clsx(classes.notiBtn, 'notiBtn')}
        aria-label='Alertas de mantenimiento'
        color='inherit'
        style={{paddingLeft: '0rem'}}
        onClick={onClickNotificationButton}>
        <Badge
          className={classes.badge}
          badgeContent={
            filterActivities(
              props.activitiesThisMonth?.list,
              props.vehicles?.list,
              props.auth,
            )?.badge
              ? filterActivities(
                  props.activitiesThisMonth?.list,
                  props.vehicles?.list,
                  props.auth,
                )?.list?.length
              : 0
          }
          color='error'>
          <BuildIcon className={clsx(classes.notiIcon, 'notiIcon')} />
        </Badge>
        <Hidden mdUp>
          <Box
            ml={4}
            fontSize={16}
            fontFamily='Poppins'
            fontWeight={Fonts.REGULAR}
            component='span'>
            <IntlMessages id='common.notifications' />
          </Box>
        </Hidden>
      </IconButton>

      <Popover
        anchorEl={anchorNotification}
        id='language-switcher'
        className={classes.crPopover}
        keepMounted
        open={Boolean(anchorNotification)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorNotification(null)}>
        <Box>
          <Box px={5} py={3}>
            <Box component='h5' fontFamily='Poppins' fontSize={16}>
              <IntlMessages id='common.alertasMantenimiento' />
            </Box>
          </Box>
          <Scrollbar className='scroll-submenu'>
            <List
              className={classes.list}
              onClick={() => {
                setAnchorNotification(null);
              }}>
              {filterActivities(
                props.activitiesThisMonth?.list,
                props.vehicles.list,
                props.auth,
              )
                .list?.sort(compareByDate)
                .map((item, index) => (
                  <NotificationItem
                    key={item.idActivity}
                    item={item}
                    vehicles={props.vehicles.list ? props.vehicles.list : []}
                  />
                ))}
            </List>
          </Scrollbar>
          <Box mt={2}>
            <Button
              className={classes.btnPopover}
              variant='contained'
              color='primary'
              onClick={() => {
                setAnchorNotification(null);
                const now = moment();
                const formattedDate = now.format('DD/MM/YYYY');
                const dataStorage = {
                  date: formattedDate,
                  user: props.auth?.user?.idUser,
                  branch: props.auth?.user?.branchSelected?.idBranch,
                };
                localStorage.setItem(
                  'notificationMaintenance',
                  JSON.stringify(dataStorage),
                );
              }}>
              <IntlMessages id='common.viewAll' />
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsPlanificacion);
