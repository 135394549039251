import React from 'react';
import MuiAlert from '@material-ui/lab/Alert/Alert';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class Alerts extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {}
  renderStyle(color) {
    switch (color) {
      case 'success':
        return '#3bdfc4';
      case 'warning':
        return '#ff9700';
      case 'error':
        return '#f40057';
      case 'info':
        return '#2196f3';
    }
  }

  render() {
    const {title, type, open} = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          open={open}>
          <Alert
            onClose={() => {
              this.props.close();
            }}
            severity={type}
            style={{backgroundColor: this.renderStyle(type), color: 'white'}}>
            {title}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
export default Alerts;
