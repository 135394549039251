import routesConfig from "../../../modules/routesConfig";
import { getDocumentFirestorePromise } from "../../services/firebase/firestore";
import { loadDatabasePromise } from "../../services/firebase/database";
import { isSmartphone } from "../../../shared/functions/typeDevice";
import { storage } from "../../services/firebase";

export let initialUrlPage = "/dashboard";

export const SettingListSideBar = (
  typeProfile = null,
  typePlan = null,
  superAdmin = false,
  paidOut = true,
  userId = null
) => {
  console.log("field of setting list sidebar");

  while (routesConfig.length > 0) {
    routesConfig.pop();
  }
  if (!paidOut) {
    initialUrlPage = "/empty";
    return;
  }
  if (superAdmin) {
    initialUrlPage = "/companies";
    const childrensAdmin = [];
    childrensAdmin.push({
      id: "companies",
      title: "maintDataCompanies",
      messageId: "sidebar.companies",
      type: "item",
      url: "/companies",
    });
    childrensAdmin.push({
      id: "plans",
      title: "maintDataPlans",
      messageId: "sidebar.plans",
      type: "item",
      url: "/plans",
    });
    if (!isSmartphone()) {
      childrensAdmin.push({
        id: "currency",
        title: "maintDataCurrency",
        messageId: "sidebar.currency",
        type: "item",
        url: "/currency",
      });
      childrensAdmin.push({
        id: "usersCompany",
        title: "maintDataUsersCompany",
        messageId: "sidebar.human-resources.administrative-users",
        type: "item",
        url: "/usersCompany",
      });
    }
    routesConfig.push({
      id: "admin-setting",
      title: "Admin",
      messageId: "sidebar.admin",
      type: "collapse",
      icon: "admin_panel_settings",
      children: childrensAdmin,
    });
  }
  const {
    backlogLoad,
    onlyRequestBacklog,
    onlyVehicle,
    dashboard,
    fleetLoad,
    graphs,
    maintenanceProgram,
    graphsInventory,
    graphsOperations,
    graphsProductivityCriticalyCost,
    graphsWorkOrders,
    justAdmin,
    massiveOil = false,
    kmHrLoad,
    laborRegistration,
    maintenancePlanLoad,
    onlyPlan,
    materialLoad,
    maintenanceStaff,
    noProductiveDayLoad,
    operationLoad,
    otCrud,
    otClose,
    otLoad,
    otReport,
    otUpdate,
    settingEspecialityLoad,
    settingGroupLoad,
    settingNoProductionLoad,
    settingNoProductiveMotiveLoad,
    settingOpertionalLostLoad,
    settingReserveTimeLoad,
    settingShiftLoad,
    settingSupplierLoad,
    settingUnitLoad,
    settingVehicleLoad,
    sparePartLoad,
    systemLoad,
    userLoad,
    sendPlanification = true,
    help = true,
    userMaintenance,
    firstPageMaintenanceStaff,
  } = typeProfile;
  console.log(typeProfile);
  const {
    administrativeUser,
    graphicsSpecial,
    laborRegister,
    maintenancePlanSpecial,
    operatorUser,
    reserveHourLostHour,
    settingSpecial,
    dashboardPlan,
  } = typePlan;
  console.log(typePlan);
  let fistPageDetected = false;
  if (dashboard && (dashboardPlan || administrativeUser)) {
    if (!fistPageDetected) {
      fistPageDetected = true;
      initialUrlPage = "/dashboard";
    }
    routesConfig.push({
      id: "dashboard",
      title: "dashboard",
      messageId: "sidebar.dashboard",
      type: "item",
      icon: "developer_board",
      url: "/dashboard",
    });
  }
  if (userLoad && !isSmartphone()) {
    if (!fistPageDetected) {
      fistPageDetected = true;
      initialUrlPage = "/human-resources/mantenance-staff";
    }
    const childrens = [];
    if (userMaintenance) {
      childrens.push({
        id: "mantenance-staff",
        title: "Mantenance-Staff",
        messageId: "sidebar.human-resources.mantenance-staff",
        type: "item",
        url: "/human-resources/mantenance-staff",
      });
    }
    if (operatorUser) {
      childrens.push({
        id: "operators",
        title: "Operators",
        messageId: "sidebar.human-resources.operators",
        type: "item",
        url: "/human-resources/operators",
      });
    }
    if (operatorUser || justAdmin) {
      childrens.push({
        id: "administrative-users",
        title: "Administrative-Users",
        messageId: "sidebar.human-resources.administrative-users",
        type: "item",
        url: "/human-resources/administrative-users",
      });
    }
    routesConfig.push({
      id: "human-resources",
      title: "human-resources",
      messageId: "sidebar.human-resources",
      type: "collapse",
      icon: "people_alt",
      children: childrens,
    });
  }
  if ((fleetLoad || systemLoad) && !onlyVehicle) {
    if (!fistPageDetected) {
      fistPageDetected = true;
      initialUrlPage = "/fleet-fleet";
    }
    const childrens = [];
    if (fleetLoad) {
      childrens.push({
        id: "fleet2",
        title: "fleet",
        messageId: "sidebar.fleet2",
        type: "item",
        url: "/fleet-fleet",
      });
      childrens.push({
        id: "vehicles",
        title: "fleet",
        messageId: "sidebar.vehicles",
        type: "item",
        url: "/fleet-vehicles",
      });
    }
    if (systemLoad) {
      childrens.push({
        id: "systems",
        title: "fleet",
        messageId: "sidebar.systems",
        type: "item",
        url: "/fleet-systems",
      });
      childrens.push({
        id: "components",
        title: "fleet",
        messageId: "sidebar.components",
        type: "item",
        url: "/fleet-components",
      });
    }
    routesConfig.push({
      id: "fleet",
      title: "fleet",
      messageId: "sidebar.fleet",
      type: "collapse",
      icon: "group_work",
      children: childrens,
    });
  }
  if (materialLoad || sparePartLoad) {
    if (!fistPageDetected) {
      fistPageDetected = true;
      initialUrlPage = "/spare-parts";
    }
    const childrens = [];
    if (sparePartLoad) {
      childrens.push({
        id: "spare-parts",
        title: "spare-parts",
        messageId: "sidebar.spare-parts",
        type: "item",
        url: "/spare-parts",
      });
    }
    if (materialLoad) {
      childrens.push({
        id: "materials",
        title: "materials",
        messageId: "sidebar.materials",
        type: "item",
        url: "/materials",
      });
    }
    routesConfig.push({
      id: "warehouse",
      title: "warehouse",
      messageId: "sidebar.warehouse",
      type: "collapse",
      icon: "view_in_ar",
      children: childrens,
    });
  }
  if (laborRegistration || otClose || otLoad || otUpdate || otReport) {
    if (!fistPageDetected || firstPageMaintenanceStaff) {
      fistPageDetected = true;
      initialUrlPage = "/work-order";
    }
    const childrens = [];
    if (otClose || otLoad || otUpdate || otCrud) {
      childrens.push({
        id: "work-order",
        title: "work-order",
        messageId: "sidebar.work-order",
        type: "item",
        url: "/work-order",
      });
    }
    if (laborRegister) {
      if (laborRegistration && administrativeUser) {
        childrens.push({
          id: "labor-registration",
          title: "labor-registration",
          messageId: "sidebar.labor-registration",
          type: "item",
          url: "/labor-registration",
        });
      }
      if (laborRegistration) {
        childrens.push({
          id: "labor-registration-with-ot",
          title: "labor-registration-with-ot",
          messageId: "sidebar.labor-registration-with-ot",
          type: "item",
          url: "/labor-registration-with-ot",
        });
      }
      if (laborRegistration && administrativeUser) {
        childrens.push({
          id: "labor-registration-inform",
          title: "labor-registration-inform",
          messageId: "sidebar.labor-registration-inform",
          type: "item",
          url: "/labor-registration-inform",
        });
      }
    }
    routesConfig.push({
      id: "admin-branch",
      title: "admin",
      messageId: "sidebar.admin-branch",
      type: "collapse",
      icon: "chrome_reader_mode",
      children: childrens,
    });
  }
  if (kmHrLoad || operationLoad || noProductiveDayLoad) {
    const childrens = [];
    if (!fistPageDetected) {
      fistPageDetected = true;
      initialUrlPage = "/operations";
    }
    if (kmHrLoad) {
      childrens.push({
        id: "operationsKmHr",
        title: "operations",
        messageId: "sidebar.operationsKmHr",
        type: "item",
        url: "/operations",
      });
    }
    if (operationLoad) {
      childrens.push({
        id: "operationsOil",
        title: "operations",
        messageId: "sidebar.operationsOil",
        type: "item",
        url: "/operations-oil",
      });
      if (reserveHourLostHour) {
        childrens.push({
          id: "operationsTimeReserve",
          title: "operations",
          messageId: "sidebar.operationsTimeReserve",
          type: "item",
          url: "/operations-times-reserves",
        });
        childrens.push({
          id: "operationsWasteOperational",
          title: "operations",
          messageId: "sidebar.operationsWasteOperational",
          type: "item",
          url: "/operations-waste-operational",
        });
      }
    }
    if (noProductiveDayLoad) {
      childrens.push({
        id: "operationsNoProductionDays",
        title: "operations",
        messageId: "sidebar.operationsNoProductionDays",
        type: "item",
        url: "/operations-no-production-days",
      });
    }
    routesConfig.push({
      id: "operations",
      title: "operations",
      messageId: "sidebar.operations",
      icon: "perm_data_setting",
      type: "collapse",
      children: childrens,
    });
  }
  if (maintenancePlanLoad || backlogLoad) {
    if (!fistPageDetected) {
      fistPageDetected = true;
      initialUrlPage = "/maintenance-plan-plan";
    }
    const childrens = [];
    if (maintenancePlanLoad || onlyPlan) {
      childrens.push({
        id: "maintenancePlanPlan",
        title: "maintenance-plan",
        messageId: "sidebar.maintenance-plan-plan",
        type: "item",
        url: "/maintenance-plan-plan",
      });
    }
    if (backlogLoad && maintenancePlanSpecial) {
      if (!onlyRequestBacklog) {
        childrens.push({
          id: "maintenancePlanBacklogs",
          title: "maintenance-plan",
          messageId: "sidebar.maintenance-plan-backlogs",
          type: "item",
          url: "/maintenance-plan-backlogs",
        });
      }
      childrens.push({
        id: "maintenancePlanRequestBacklogs",
        title: "maintenance-plan",
        messageId: "sidebar.maintenance-plan-request-backlogs",
        type: "item",
        url: "/maintenance-plan-request-backlog",
      });
    }
    if (maintenancePlanLoad && !isSmartphone()) {
      childrens.push({
        id: "maintenancePlanInspection",
        title: "maintenance-plan",
        messageId: "sidebar.maintenance-plan-inspection",
        type: "item",
        url: "/maintenance-plan-inspection",
      });
      childrens.push({
        id: "maintenancePlanMaintenanceInterval",
        title: "maintenance-plan",
        messageId: "sidebar.maintenance-plan-maintenance-interval",
        type: "item",
        url: "/maintenance-plan-maintenance-interval",
      });
      if (maintenancePlanSpecial) {
        childrens.push({
          id: "maintenancePlanConditionsMonitoring",
          title: "maintenance-plan",
          messageId: "sidebar.maintenance-plan-conditions-monitoring",
          type: "item",
          url: "/maintenance-plan-condition-monitoring",
        });
      }
      childrens.push({
        id: "maintenancePlanMinorComponent",
        title: "maintenance-plan",
        messageId: "sidebar.maintenance-plan-minor-component",
        type: "item",
        url: "/maintenance-plan-minor-component",
      });
      if (maintenancePlanSpecial) {
        childrens.push({
          id: "maintenancePlanMayorComponent",
          title: "maintenance-plan",
          messageId: "sidebar.maintenance-plan-mayor-component",
          type: "item",
          url: "/maintenance-plan-mayor-component",
        });
      }
    }
    if (maintenancePlanLoad && !isSmartphone()) {
      childrens.push({
        id: "maintenancePlanImprovementProduct",
        title: "maintenance-plan",
        messageId: "sidebar.maintenance-plan-improvement-product",
        type: "item",
        url: "/maintenance-plan-improvement-product",
      });
    }
    if (sendPlanification && !isSmartphone()) {
      childrens.push({
        id: "maintenancePlanSend",
        title: "maintenance-plan",
        messageId: "sidebar.maintenance-plan-send",
        type: "item",
        url: "/maintenance-plan-send",
      });
    }

    routesConfig.push({
      id: "maintenancePlan",
      title: "maintenance-plan",
      messageId: "sidebar.maintenance-plan",
      type: "collapse",
      icon: "calendar_today",
      children: childrens,
    });
  }
  if (
    graphs ||
    graphsInventory ||
    graphsOperations ||
    graphsProductivityCriticalyCost ||
    graphsWorkOrders
  ) {
    const childrens = [];
    if (graphs) {
      childrens.push({
        id: "maintenance",
        title: "maintenance",
        messageId: "sidebar.graphics.maintenance",
        type: "item",
        url: "/graphics/maintenance",
      });
      if (maintenanceProgram) {
        childrens.push({
          id: "maintenance-program",
          title: "maintenance-program",
          messageId: "sidebar.graphics.maintenanceProgram",
          type: "item",
          url: "/graphics/maintenance-program",
        });
      }
    }
    if (graphsProductivityCriticalyCost && graphicsSpecial) {
      childrens.push({
        id: "labor-productivity",
        title: "labor-productivity",
        messageId: "sidebar.graphics.laborProductivity",
        type: "item",
        url: "/graphics/labor-productivity",
      });
    }
    if (graphsWorkOrders) {
      childrens.push({
        id: "work-order",
        title: "work-order",
        messageId: "sidebar.graphics.work-order",
        type: "item",
        url: "/graphics/work-order",
      });
    }
    if (graphsOperations && graphicsSpecial) {
      childrens.push({
        id: "backlogs",
        title: "backlogs",
        messageId: "sidebar.graphics.backlogs",
        type: "item",
        url: "/graphics/backlogs",
      });
    }
    if (graphsProductivityCriticalyCost && graphicsSpecial) {
      childrens.push({
        id: "criticality",
        title: "criticality",
        messageId: "sidebar.graphics.criticality",
        type: "item",
        url: "/graphics/criticality",
      });
    }
    if (graphsProductivityCriticalyCost) {
      childrens.push({
        id: "maintenance-cost",
        title: "maintenance-cost",
        messageId: "sidebar.graphics.maintenance-cost",
        type: "item",
        url: "/graphics/maintenance-cost",
      });
    }
    if (graphsInventory && graphicsSpecial) {
      childrens.push({
        id: "stock",
        title: "stock",
        messageId: "sidebar.graphics.stock",
        type: "item",
        url: "/graphics/stock",
      });
    }
    routesConfig.push({
      id: "graphics-first",
      title: "Graphics",
      messageId: "sidebar.graphics",
      type: "collapse",
      icon: "stacked_line_chart",
      children: childrens,
    });
  }
  if (graphs) {
    const children = [];
    if (graphicsSpecial) {
      children.push({
        id: "operations-graphics",
        title: "operations",
        messageId: "sidebar.graphics.operations",
        type: "item",
        url: "/graphics/operations",
      });
    }
    children.push({
      id: "oil-graphics",
      title: "oil",
      messageId: "sidebar.graphics.oil",
      type: "item",
      url: "/graphics/oil",
    });
    routesConfig.push({
      id: "graphics-operations",
      title: "Graphics",
      messageId: "sidebar.graphics-operations",
      type: "collapse",
      icon: "bar_chart",
      children: children,
    });
  }
  if (help) {
    routesConfig.push({
      id: "help",
      title: "help",
      messageId: "sidebar.help",
      type: "item",
      icon: "help",
      url: "/help",
    });
  }
  if (
    (justAdmin ||
      settingEspecialityLoad ||
      settingGroupLoad ||
      settingNoProductionLoad ||
      settingNoProductiveMotiveLoad ||
      settingShiftLoad ||
      settingSupplierLoad ||
      settingVehicleLoad ||
      settingUnitLoad) &&
    !isSmartphone()
  ) {
    const childrens = [];
    if (justAdmin) {
      childrens.push({
        id: "branch",
        title: "branch",
        messageId: "sidebar.setting.branch",
        type: "item",
        url: "/setting/branch",
      });
    }
    if (settingEspecialityLoad) {
      childrens.push({
        id: "list-specialitys",
        title: "listSpecialitys",
        messageId: "sidebar.setting.list-specialitys",
        type: "item",
        url: "/setting/list-specialitys",
      });
    }
    if (settingShiftLoad) {
      childrens.push({
        id: "list-shift",
        title: "ListShift",
        messageId: "sidebar.setting.list-shift",
        type: "item",
        url: "/setting/list-shift",
      });
    }
    if (settingSupplierLoad) {
      childrens.push({
        id: "supplier",
        title: "supplier",
        messageId: "sidebar.setting.supplier",
        type: "item",
        url: "/setting/supplier",
      });
    }
    if (settingSupplierLoad) {
      childrens.push({
        id: "supplierMaterials",
        title: "supplierMaterials",
        messageId: "sidebar.setting.supplierMaterials",
        type: "item",
        url: "/setting/supplier-materials",
      });
    }
    if (settingGroupLoad) {
      childrens.push({
        id: "list-groups",
        title: "ListGroups",
        messageId: "sidebar.setting.list-groups",
        type: "item",
        url: "/setting/list-groups",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "list-positions",
        title: "list-positions",
        messageId: "sidebar.setting.list-positions",
        type: "item",
        url: "/setting/list-positions",
      });
    }
    if (settingVehicleLoad) {
      childrens.push({
        id: "vehicles",
        title: "vehicles",
        messageId: "sidebar.setting.vehicles",
        type: "item",
        url: "/setting/vehicles",
      });
    }
    if (settingUnitLoad) {
      childrens.push({
        id: "list-unit-measurements",
        title: "listUnitMeasurements",
        messageId: "sidebar.setting.list-unit-measurements",
        type: "item",
        url: "/setting/list-unit-measurements",
      });
    }
    if (justAdmin && settingSpecial) {
      childrens.push({
        id: "list-type-work",
        title: "listTypeWork",
        messageId: "sidebar.setting.list-type-work",
        type: "item",
        url: "/setting/list-type-work",
      });
    }
    if (settingReserveTimeLoad && settingSpecial) {
      childrens.push({
        id: "list-reserve-time",
        title: "listReserveTime",
        messageId: "sidebar.setting.list-reserve-time",
        type: "item",
        url: "/setting/list-reserve-time",
      });
    }
    if (settingOpertionalLostLoad && settingSpecial) {
      childrens.push({
        id: "list-operational-lost",
        title: "listOperationalLost",
        messageId: "sidebar.setting.list-operational-lost",
        type: "item",
        url: "/setting/list-operational-lost",
      });
    }
    if (settingNoProductiveMotiveLoad && settingSpecial) {
      childrens.push({
        id: "list-type-holidays",
        title: "listTypeHolidays",
        messageId: "sidebar.setting.list-type-holidays",
        type: "item",
        url: "/setting/list-type-holidays",
      });
    }
    routesConfig.push({
      id: "setting",
      title: "Setting",
      messageId: "sidebar.setting",
      type: "collapse",
      icon: "settings_applications",
      children: childrens,
    });
  }
  if (maintenanceStaff && isSmartphone()) {
    const routesConfigCopy = [...routesConfig];
    routesConfig[0] = routesConfigCopy[1];
    routesConfig[1] = routesConfigCopy[0];
    initialUrlPage = "/work-order";
  }
  if (
    justAdmin ||
    (massiveOil && !isSmartphone()) ||
    userId === "NVBzgkQloCZ2WtZiPLYJdlFZ7FC2"
  ) {
    const childrens = [];
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-vehicles",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-vehicles",
        type: "item",
        url: "/massive-upload-vehicles",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-systems",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-systems",
        type: "item",
        url: "/massive-upload-systems",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-spare-part",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-spare-part",
        type: "item",
        url: "/massive-upload-spare-part",
      });
    }
    if (justAdmin || userId === "NVBzgkQloCZ2WtZiPLYJdlFZ7FC2") {
      childrens.push({
        id: "massive-upload-stock-spare-part",
        title: "massive-upload-stock",
        messageId: "sidebar.massive-upload-stock-spare-part",
        type: "item",
        url: "/massive-upload-stock-spare-part",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-material",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-material",
        type: "item",
        url: "/massive-upload-material",
      });
    }
    if (justAdmin || userId === "NVBzgkQloCZ2WtZiPLYJdlFZ7FC2") {
      childrens.push({
        id: "massive-upload-stock-material",
        title: "massive-upload-stock-material",
        messageId: "sidebar.massive-upload-stock-material",
        type: "item",
        url: "/massive-upload-stock-material",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-work-order",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-work-order",
        type: "item",
        url: "/massive-upload-work-order",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-operator",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-operator",
        type: "item",
        url: "/massive-upload-operator",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-maitenance",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-maintenance",
        type: "item",
        url: "/massive-upload-maintenance",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-km-hr",
        title: "massive-upload",
        messageId: "sidebar.operationsKmHr",
        type: "item",
        url: "/massive-upload-km-hr",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-adjust-spare-part",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-spare-part-adjust",
        type: "item",
        url: "/massive-upload-adjust-spare-part",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-adjust-material",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-material-adjust",
        type: "item",
        url: "/massive-upload-adjust-material",
      });
    }
    if (justAdmin || massiveOil) {
      childrens.push({
        id: "massive-upload-oil",
        title: "massive-upload",
        messageId: "sidebar.operationsOil",
        type: "item",
        url: "/massive-upload-oil",
      });
    }
    if (justAdmin) {
      childrens.push({
        id: "massive-upload-suppliers",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-suppliers",
        type: "item",
        url: "/massive-upload-suppliers",
      });
    }

    if (justAdmin) {
      childrens.push({
        id: "massive-upload-material-suppliers",
        title: "massive-upload",
        messageId: "sidebar.massive-upload-material-suppliers",
        type: "item",
        url: "/massive-upload-material-suppliers",
      });
    }

    routesConfig.push({
      id: "massive-upload",
      title: "massive-upload",
      messageId: "sidebar.massive-upload",
      icon: "backup",
      type: "collapse",
      children: childrens,
    });
  }
};

export const loadUser = async (userId) => {
  let superAdmin = false;
  if (userId === "YB9uTAjtLqgD5E4LC4oMAj70aZ42") {
    superAdmin = true;
  }
  const userBasicRef = await getDocumentFirestorePromise("/users", userId);
  const USER_BASIC_INFO = userBasicRef.data();
  if (!USER_BASIC_INFO) {
    return false;
  }
  const companyRef = await getDocumentFirestorePromise(
    "mainData/" + USER_BASIC_INFO.idCompany + "/companyInformation",
    USER_BASIC_INFO.idCompany
  );
  const COMPANY_INFO = companyRef.data();
  if (!COMPANY_INFO) {
    return;
  }
  const typePlanRef = await loadDatabasePromise(
    "typePlan",
    COMPANY_INFO.typePlan
  );
  const DATA_PLAN = typePlanRef.val();
  if (!DATA_PLAN) {
    return false;
  }
  const typeProfile = await loadDatabasePromise(
    "typesProfiles",
    USER_BASIC_INFO.typeProfile
  );
  const DATA_TYPE_PROFILE = typeProfile.val();
  if (!DATA_TYPE_PROFILE) {
    return false;
  }
  const pathUserData =
    "mainData/" + USER_BASIC_INFO.idCompany + "/" + USER_BASIC_INFO.pathUser;
  const userDataRef = await getDocumentFirestorePromise(pathUserData, userId);
  const USER_DATA = userDataRef.data();
  if (!USER_DATA) {
    return false;
  }
  if (!USER_DATA.active) {
    return false;
  }
  if (
    USER_BASIC_INFO.typeProfile === "operator" ||
    USER_BASIC_INFO.typeProfile === "maintenanceStaff"
  ) {
    const pathBranch = `${USER_BASIC_INFO.idCompany}/listBranch`;
    const branchs = await loadDatabasePromise(pathBranch, USER_DATA.branch);
    if (branchs.val()) {
      USER_DATA.branchs = [branchs.val()];
    }
  }
  SettingListSideBar(
    DATA_TYPE_PROFILE,
    DATA_PLAN,
    superAdmin,
    COMPANY_INFO.paidOut,
    userId
  );
  const photoUrl = await loadPath(
    "mainData/" + USER_BASIC_INFO.idCompany + "/administrativeUsers/" + userId
  );
  const photoCompanyUrl = await loadLogo(
    "maintData/logos/" + USER_BASIC_INFO.idCompany
  );
  return {
    ...USER_DATA,
    displayName: USER_DATA.name,
    token: "access-token",
    role: "user",
    company: USER_BASIC_INFO.idCompany,
    paid: COMPANY_INFO.paid,
    typeProfile: DATA_TYPE_PROFILE,
    typeProfileName: USER_BASIC_INFO.typeProfile,
    typePlan: DATA_PLAN,
    branchSelected: USER_DATA.branchs ? USER_DATA.branchs[0] : null,
    photoURL: photoUrl,
    logoCompany: photoCompanyUrl,
  };
};

const loadPath = async (pathReference) => {
  let path = "https://via.placeholder.com/150";
  await storage
    .ref()
    .child(pathReference)
    .getDownloadURL()
    .then((url) => {
      path = url;
      console.log(url);
    })
    .catch((error) => {
      console.log(error);
    });
  return path;
};

const loadLogo = async (pathReference) => {
  let path =
    "https://firebasestorage.googleapis.com/v0/b/main-data-test.appspot.com/o/maintData%2FempresaTest%2Flogo-with-name-4.png?alt=media&token=bb926a0b-4d58-4bc7-aae6-98083395cf11";
  await storage
    .ref()
    .child(pathReference)
    .getDownloadURL()
    .then((url) => {
      path = url;
      console.log(url);
    })
    .catch((error) => {
      console.log(error);
    });
  return path;
};
