import {put, call} from '@redux-saga/core/effects';
import {
  updateDocumentFirestore,
  deleteFileWithStorage,
} from '../services/firebase/firestore';

export const operationDeleteFileDocument = {
  name: 'DELETE_FILE_DOCUMENT',
  reducer: {
    initialState: {deleting: false, deleted: false, deleteFailed: false},
    cases: [
      {
        name: 'DELETE_FILE_DOCUMENT_',
        newState: {deleting: true, deleted: false, deleteFailed: false},
      },
      {
        name: 'UPDATED_FILE_DOCUMENT_',
        newState: {deleting: false, deleted: true, deleteFailed: false},
      },
      {
        name: 'DELETE_FILE_DOCUMENT_FAILED_',
        newState: {deleting: false, deleted: false, deleteFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        deleteWithFiles: (payload) => {
          return {type: 'DELETE_FILE_DOCUMENT_' + name, payload: payload};
        },
        deletedWithFiles: () => {
          return {type: 'UPDATED_FILE_DOCUMENT_' + name};
        },
        deleteWithFilesFailed: () => {
          return {type: 'DELETE_FILE_DOCUMENT_FAILED_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'DELETE_FILE_DOCUMENT_',
    function: (name) => {
      return function* deleteWithFiles({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, idDocument, dataDocument, file} = payload;
          yield call(updateDocumentFirestore, path, idDocument, dataDocument);
          yield call(deleteFileWithStorage, file.path);
          yield put({type: 'DELETE_SUCCESS'});
        } catch (e) {
          console.log(e);
          yield put({type: 'DELETE_FAILED'});
        }
      };
    },
  },
};
