import React from 'react';
export const graphicsPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/maintenance',
        component: React.lazy(() => import('./Pages/Maintenance')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/failure-Cause',
        component: React.lazy(() => import('./Pages/FailureCause')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/operations',
        component: React.lazy(() => import('./Pages/Operations')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/work-order',
        component: React.lazy(() => import('./Pages/WorkOrder')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/maintenance-cost',
        component: React.lazy(() => import('./Pages/MaintenanceCost')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/criticality',
        component: React.lazy(() => import('./Pages/Criticality')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/oil',
        component: React.lazy(() => import('./Pages/Oil')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/backlogs',
        component: React.lazy(() => import('./Pages/Backlogs')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/stock',
        component: React.lazy(() => import('./Pages/Stock')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/labor-productivity',
        component: React.lazy(() => import('./Pages/LaborProductivity')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/graphics/maintenance-program',
        component: React.lazy(() => import('./Pages/MaintenanceProgram')),
      },
    ],
  },
];
