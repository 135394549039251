import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {useDispatch} from 'react-redux';
import useStyles from './AppHeader.style';
import AppLogo from '../../../../shared/components/AppLogo';
import {connect} from 'react-redux';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import AppLogoCompany from '../../../../shared/components/AppLogoCompany';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import Notifications from '../../Notifications';
import NotificationsPlanificacion from '../../NotificationsPlanificacion';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const AppHeader = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [branchSelected, setBranchSelected] = React.useState(0);

  return (
    <>
      <AppBar color='inherit' className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <Grid item>
              <AppLogo />
            </Grid>
            <Grid item>
              <FormControl
                variant='outlined'
                style={{
                  minWidth: 200,
                  marginLeft: 20,
                }}
                key={'branchFormControl'}>
                <InputLabel id='label-goal'>
                  {props.auth.companyInfo
                    ? props.auth.companyInfo[0]
                      ? props.auth.companyInfo[0].nameBranch
                      : 'Sucursales'
                    : 'Sucursales'}
                </InputLabel>
                <Select
                  native
                  value={branchSelected}
                  onChange={(event) => {
                    setBranchSelected(event.target.value);
                    dispatch({
                      type: 'AUTH_SET_BRANCH_USER',
                      payload: props.auth.user.branchs[event.target.value],
                    });
                    dispatch({
                      type: 'LOAD_FLEETS',
                      payload: {
                        path: 'mainData/' + props.auth.user.company + '/fleets',
                        idName: 'idFleet',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_VEHICLES',
                      payload: {
                        path:
                          'mainData/' + props.auth.user.company + '/vehicles',
                        idName: 'idVehicle',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_SPARE_PARTS',
                      payload: {
                        path:
                          'mainData/' + props.auth.user.company + '/spareParts',
                        idName: 'idSparePart',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_MATERIALS',
                      payload: {
                        path:
                          'mainData/' + props.auth.user.company + '/materials',
                        idName: 'idMaterial',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_LIST_GROUPS',
                      payload: {
                        path: 'mainData/' + props.auth.user.company + '/groups',
                        idName: 'idGroup',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_LIST_SHIFT',
                      payload: {
                        path:
                          'mainData/' + props.auth.user.company + '/shifts/',
                        idName: 'idShift',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_OPERATORS_USERS',
                      payload: {
                        path:
                          'mainData/' +
                          props.auth.user.company +
                          '/operatorsUsers',
                        idName: 'idOperatorUser',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_MAINTENANCE_STAFF_USERS',
                      payload: {
                        path:
                          'mainData/' +
                          props.auth.user.company +
                          '/maintenanceStaffUsers',
                        idName: 'idMaintenanceStaffUser',
                        conditions: [],
                      },
                    });
                    dispatch({
                      type: 'LOAD_ADMINISTRATIVE_USERS',
                      payload: {
                        path:
                          'mainData/' +
                          props.auth.user.company +
                          '/administrativeUsers',
                        idName: 'idAdministrativeUser',
                        conditions: [],
                      },
                    });
                  }}
                  label={'Sucursal'}
                  name={'branch'}
                  id={'branch'}>
                  {props.auth.user.branchs
                    ? props.auth.user.branchs.map((option, index) => {
                        return (
                          <option value={index} key={option.name + index}>
                            {option.name}
                          </option>
                        );
                      })
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Grid item>
                  <Tooltip
                    title={
                      <h5 style={{color: 'white'}}>
                        {'Correo de propuestas de mejora'}
                      </h5>
                    }>
                    <IconButton
                      aria-label='help'
                      className={clsx(classes.notiBtn, 'notiBtn')}
                      onClick={() => {
                        window.open(
                          'mailto:contacto@maintdata.cl?subject=Propuestas de mejoras&body=Escribe tus propuestas de mejoras',
                        );
                      }}>
                      <MailIcon fontSize={'small'} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Notifications />
                </Grid>
                <Grid item>
                  <NotificationsPlanificacion />
                </Grid>
                <Grid item>
                  <AppLogoCompany
                    logoCompany={
                      props.auth.user.logoCompany
                        ? props.auth.user.logoCompany
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default connect(mapStateToProps)(AppHeader);
