import React from 'react';

export const humanResourcesPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/human-resources/mantenance-staff',
        component: React.lazy(() => import('./Pages/MantenanceStaffPage')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/human-resources/administrative-users',
        component: React.lazy(() => import('./Pages/AdministrativeUsersPage')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/human-resources/operators',
        component: React.lazy(() => import('./Pages/OperatorsPage')),
      },
    ],
  },
];
